import React, { useEffect, useState } from 'react'
import HeaderSearch from '../community/headerSearch'
import chevron_down from "../../assets/img/modals/chevron-down.svg"
import getNewsletter from '../../services/getNewsletter';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import CommunityFooter from '../community/footer';
import EmptyState from '../../pages/empty-state';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { audiencePosts } from '../../services/postRequests/audience';
import { apiUrl } from '../../utils/url';
import { hideLoader } from '../loader';

let params = [];
const AnalyticsLinks = ({ showDetailedAnalytics }) => {
    const { slug } = useSelector(state => state?.slugReducer);
    const [isDesc, setIsDesc] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [allLinks, setallLinks] = useState([]);
    const pro = useSelector(state => state?.proReducer);
    const history = useHistory();
    const planType = useSelector(state => state?.plantypeReducer);
    const [filter, setFilter] = useState({
        orderBy: '',
        orderType: 'desc',
        page: 1,
        count: localStorage?.posts_count ?? 10
    });
    const filterToggler = (value) => {
        setFilter({
            ...filter,
            orderBy: value,
            orderType: isDesc ? 'asc' : 'desc',
        });
        setIsDesc(!isDesc); // Toggle between ascending and descending
    };
    useEffect(() => {
        params = [];
        if (slug) {
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key))
                    if (filter[key]) params.push(`${[key]}=${filter[key]}`)

            };
            if (filter?.page) {
                setIsLoading(true)        
                callLinks()
            }
        }
    }, [slug, filter, pro, planType]);
    const callLinks = () => {
        setIsLoading(true)
        var api = apiUrl + '/project/statistics/links/get/' + slug + '?' + params?.join("&");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const setOptions = {
            method: "GET",
            headers: myHeaders
        }
        audiencePosts(null, api, hideLoader, setOptions, "custom_message").then((response) => {
            setallLinks(response?.paginated);
            setTimeout(() => {
                setIsLoading(false)
            }, 2200);
        }).catch((err) => {
            setIsLoading(false)
            console.log('err', err);
        })
    }
    const sendToPage = () => {
        history.push("/page-editor")
    }
    return (
        <>
            {!isLoading && !allLinks?.data?.length > 0 && (filter?.filter == 'All' || !filter?.filter) ?
                <div class="section-main sec-main-u p-0 position-relative">
                    <EmptyState from={"posts"} firstText={'*Cricket Noises*'} secondText={"Start writing blog posts and get your thoughts out into the world."} buttonText={"Create Post"} clickedFunction={sendToPage} />
                </div>
                :
                <div class="section-main d-flex flex-column justify-content-between bg-transparent gap-3" style={{ height: "90%" }}>
                    <div className='d-flex flex-column'>
                        <div className='d-flex gap-2 align-items-center justify-content-start mt-20px'>
                            <div>
                                <HeaderSearch filter={filter} setfilter={setFilter} name="Links" />
                            </div>
                        </div>
                        <div className='links-analytics-section'>
                            {isLoading ? (
                                Array(4).fill(null).map((width, index) => (
                                    <div key={index} className='links-analytics-main'>
                                        <Skeleton width={45} height={45}
                                            baseColor='#FAFAFA'
                                            highlightColor='#EEEDEE'
                                            borderRadius="100%"
                                        />
                                        <div className='d-flex flex-column gap-1'>
                                            <Skeleton width={181} baseColor='#FAFAFA' highlightColor='#EEEDEE' height={40} borderRadius="0.5rem" />
                                            <Skeleton width={181} baseColor='#FAFAFA' highlightColor='#EEEDEE' height={25} borderRadius="0.5rem" />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                allLinks?.data?.map((link, index) => (
                                    <div key={index} onClick={() => showDetailedAnalytics(link?.link, link?.website_name)} className='links-analytics-main'>
                                        <img className='favicon-img' src={link?.icon} alt="" srcset="" />
                                        <div className='d-flex flex-column gap-1'>
                                            <div className='first-half'>
                                                <div className='text-gray'>{link?.website_name}</div>
                                                <div className='text-truncate text-dark' style={{ maxWidth: "150px" }}>{link?.link}</div>
                                            </div>
                                            <div className='clicks'>Total Clicks - {link?.total_clicks}</div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div class="section-footer d-flex align-items-center justify-content-between">
                        <CommunityFooter from={"posts"} loading={isLoading} filter={filter} setfilter={setFilter} total={allLinks?.total} last_page={allLinks?.last_page} current_page={allLinks?.current_page} />
                    </div>
                </div>
            }
        </>
    )
}

export default AnalyticsLinks