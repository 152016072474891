import React, { useEffect, useState } from 'react'
import dragIcon from "../../assets/img/page/drag-icon.svg";
import copyComponent from "../../assets/img/copy-clipboard.svg";
import plusIcon from "../../assets/img/thin-plus-icon.svg";
import trashIcon from "../../assets/img/page/trash.svg";
import openedEyeIcon from "../../assets/img/page/opened-eye-icon.svg";
import closedEyeIcon from "../../assets/img/page/closed-eye-icon.svg";
import gearPageIcon from "../../assets/img/page/gear.svg";
import visibleIcon from "../../assets/img/page/mini-graph-icon.svg";
import { useDispatch, useSelector } from 'react-redux';
import { mainPath } from '../../pages/page-editor';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import editIcon from "../../assets/img/page/edit-block.svg"
import returnIcon from "../../assets/img/modals/return.svg"
import duplicateIcon from "../../assets/img/duplicate-Icon.svg";
import darkChevronUpIcon from "../../assets/img/page/dark-cheveron-up.svg";
import CroppieModal from '../croppie-modal';
import CreateLeadMagnetWidgetModal from '../page-editor/block-editor/sub-modals/createLeadMagnetWidgetModal';
import { useAlert } from '../../contexts/alertContext';
import { apiUrl, pageUrl, protocol } from '../../utils/url';
import { hideLoader, showLoader } from '../loader';
// block icons
import twitterIcon from "../../assets/img/page/twitter.svg";
import linkIcon from "../../assets/img/page/link-block-icon.svg";
import embed_svg from "../../assets/img/page/new-embed-icon.svg"
import spotify_sync from "../../assets/img/spotify-sync.svg"
import leadMagnetIcon from "../../assets/img/lead-magnet.svg"
import youtubeIcon from "../../assets/img/modals/youtube-icon.svg";
import tiktokIcon from "../../assets/img/page/new-tiktok-icon.svg";
import emailIcon from "../../assets/img/page/email-widget-icon.svg";
import textBlockIcon from "../../assets/img/page/new-text-block-icon.svg";
import productBlockIcon from "../../assets/img/page/product-block-icon.svg";
import MeetingBlockIcon from "../../assets/img/calendar.svg"
import instagramIcon from "../../assets/img/instagram.svg";
import postIcon from "../../assets/img/post-block.svg";
import folderIcon from "../../assets/img/page/blue-folder-icon.svg";
import contactIcon from "../../assets/img/page/new-contact-icon.svg";
// block modals
import EditLinkModal from '../page-editor/block-editor/sub-modals/editLinkModal';
import CreateEmailWidgetModal from '../page-editor/block-editor/sub-modals/createEmailWidgetModal';
import CreateTextBlockModal from '../page-editor/block-editor/sub-modals/createTextBlockModal';
import CreateFolderModal from '../page-editor/block-editor/sub-modals/createFolderModal';
import CreateProductWidgetModal from '../page-editor/block-editor/sub-modals/createProductWidgetModal';
import CreateMeetingProductWidgetModal from '../page-editor/block-editor/sub-modals/createMeetingProductWidgetModal';
import CreateYoutubeWidgetModal from '../page-editor/block-editor/sub-modals/createYoutubeWidgetModal';
import CreateTiktokWidgetModal from '../page-editor/block-editor/sub-modals/createTiktokWidgetModal';
import CreateInstagramWidgetModal from '../page-editor/block-editor/sub-modals/createInstagramWidgetModal';
import CreateTwitterWidgetModal from '../page-editor/block-editor/sub-modals/createTwitterWidgetModal';
import CreateContactModal from '../page-editor/block-editor/sub-modals/createContactModal.';
import CreateZaapPostsModal from '../page-editor/block-editor/sub-modals/createZaapPostsModal';
import CreateEmbedBlockModal from '../page-editor/block-editor/sub-modals/createEmbedBlockModal';
import CreateSpotifySyncBlockModal from '../page-editor/block-editor/sub-modals/createSpotifySyncWidgetModal';
import BlockEditor from '../page-editor/block-editor';
import CreateLinkModal from '../page-editor/block-editor/sub-modals/createLinkModal';
import Skeleton from 'react-loading-skeleton';
import CongratsModal from '../page-editor/congrats-modal';
import UploadImageBanner from '../page-editor/block-editor/sub-modals/uploadImageBanner';
import NewConfirmationModal from '../common/new-confirmation-modal';

let script_tag = `<script src="https://sortablejs.github.io/Sortable/Sortable.js"></script>`
const folderSortable = (setIframeURL) => {

    const el = document.querySelector('.editor-main')
    let folders_sortable = Sortable?.create(el, {
        animation: 150,
        handle: '#drag',
        // filter: '.ignore-class',
        draggable: '.block',
        onUpdate: (event) => {
            let folders = [];
            $('.editor-main> .block').each((i, elm) => {
                let folder = {
                    folder_id: elm.dataset.blockid,
                    order_no: i
                };
                folders.push(folder);
            })

            if (folders.length > 0)
                $.ajax({
                    type: 'POST',
                    url: `${apiUrl}/project/sort`,
                    data: {
                        request_type: 'order',
                        folders,
                        project: localStorage.slug,
                    },
                    headers: {
                        'Authorization': `Bearer ${localStorage?.getItem("token")}`
                    },
                    dataType: 'json',
                    success: (response) => {
                        setTimeout(() => {
                            setIframeURL(protocol + '' + pageUrl + '/' + localStorage?.slug + '?iframe=1&v=' + Math.random(100));
                        }, 500);
                    }
                });
            /* Refresh iframe */
        }
    });
}

const Content = ({ iframeURL, setIframeURL, setconfirmationLinkModal, confirmationLinkModal, callGetProject }) => {
    const modalsConstant = { show: false };
    const draggableIcon = () => <img src={dragIcon} id="drag" className="cursor-grab" alt="" />
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const blockList = useSelector(state => state?.blockReducer)
    const history = useHistory();
    const [showCroppieModal, setshowCroppieModal] = useState(false);
    const { setAlert } = useAlert();
    const [error, setError] = useState([]);
    const { state } = useLocation();
    const dispatch = useDispatch()
    const [emailWidgetModal, setemailWidgetModal] = useState(modalsConstant);
    const [folderModal, setfolderModal] = useState(modalsConstant);
    const [productModal, setproductModal] = useState(modalsConstant);
    const [calendarProductModal, setcalendarProductModal] = useState(modalsConstant);
    const [leadMagnetModal, setLeadMagnetModal] = useState(modalsConstant);
    const [youtubeModal, setyoutubeModal] = useState(modalsConstant);
    const [tiktokModal, settiktokModal] = useState(modalsConstant);
    const [instagramModal, setinstagramModal] = useState(modalsConstant);
    const [twitterModal, settwitterModal] = useState(modalsConstant);
    const [textBlockModal, settextBlockModal] = useState(modalsConstant);
    const [embedBlockModal, setembedBlockModal] = useState(modalsConstant);
    const [contactModal, setcontactModal] = useState(modalsConstant);
    const [zaapPostsModal, setzaapPostsModal] = useState(modalsConstant);
    const [spotifySyncBlockModal, setSpotifySyncBlockModal] = useState(modalsConstant);
    const editLinkModal = useSelector(state => state.editLinkModal);
    const [blockModal, setBlockModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const { customizeSeoReducer, bannerModal } = useSelector(state => state);
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null });

    const [linkModal, setlinkModal] = useState({
        show: false,
        parent_id: null
    });
    const CHILDREN_BLOCK_TYPES = ['link', 'product', 'meeting'];
    const MODAL_HANDLERS = {
        email_widget: {
            setState: setemailWidgetModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)?.description
            })
        },
        folder: {
            setState: setfolderModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name
            })
        },
        product: {
            setState: setproductModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)?.product
            })
        },
        meeting: {
            setState: setcalendarProductModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)?.product
            })
        },
        lead_magnet: {
            setState: setLeadMagnetModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        },
        youtube: {
            setState: setyoutubeModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        },
        tiktok: {
            setState: settiktokModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        },
        instagram: {
            setState: setinstagramModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        },
        twitter: {
            setState: settwitterModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        },
        text_block: {
            setState: settextBlockModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        },
        embed: {
            setState: setembedBlockModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        },
        contact: {
            setState: setcontactModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        },
        zaap_posts: {
            setState: setzaapPostsModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        },
        spotify_sync: {
            setState: setSpotifySyncBlockModal,
            process: (data, e) => ({
                show: true,
                id: data?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        },
        linkModal: {
            setState: setlinkModal,
            process: (data, e) => ({
                show: true,
                parent_id: data?.id
            })
        }
    };
    const editModal = (e, data) => {
        const modalType = data?.type;
        const handler = MODAL_HANDLERS[modalType];

        if (!handler) {
            if (modalType === 'link') {
                let description = JSON?.parse(data?.details?.meta)?.link_description;
                try {
                    description = description ? decodeURIComponent(description) : '';
                } catch (error) {
                    console.error("Error decoding description:", error);
                }

                dispatch({
                    type: "SET_EDIT_LINK_MODAL",
                    payload: {
                        show: true,
                        id: data?.id,
                        link_title: data?.name,
                        destination_url: JSON?.parse(data?.details?.meta)?.destination_url,
                        link_description: description,
                        open_in_same_tab: JSON?.parse(data?.details?.meta)?.open_in_same_tab,
                        button_text: JSON?.parse(data?.details?.meta)?.button_text,
                        imageData: JSON?.parse(data?.details?.meta)?.link_image,
                        link_image: JSON?.parse(data?.details?.meta)?.link_image,
                        image_url: JSON?.parse(data?.details?.meta)?.link_image,
                    }
                });
                return;
            }
            return;
        }

        handler.setState(handler.process(data, e));
    };
    const showModal = (paramsName) => {
        // setShowParent(true)
        history.push({
            state: { show: true }
        })
        setTimeout(() => {
            history.push({
                pathname: `${basePath}/${mainPath}/${paramsName}`,
                state: { show: true, from: 'folder_block' }
            })
        }, 200);
    }
    const icons = {
        folder: folderIcon,
        link: linkIcon,
        email_widget: emailIcon,
        text_block: textBlockIcon,
        product: productBlockIcon,
        youtube: youtubeIcon,
        tiktok: tiktokIcon,
        twitter: twitterIcon,
        embed: embed_svg,
        contact: contactIcon,
        meeting: MeetingBlockIcon,
        instagram: instagramIcon,
        zaap_posts: postIcon,
        spotify: spotify_sync,
        spotify_sync: spotify_sync,
        lead_magnet: leadMagnetIcon
    };

    const handleSubmit = (e, values, blockName, isUpdate, blockTitle) => {

        // console.log("Handle Submit")
        let end_point = isUpdate;
        showLoader();
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const errorList = []
        const formdata = new FormData();
        let formValues = {};
        if (blockTitle == "twitter") {
            if (values?.connect == 1) {
                formValues = {
                    block: blockName,
                    project: slug,
                    connect: 1,
                    end_point: end_point
                }
                const valueToStore = { ...values, ...formValues, blockName: blockTitle, isUpdate }
                localStorage.setItem("twitter_values", JSON.stringify(valueToStore))
            }
            else if (values?.connect == 0 && values?.callback == 1) {
                endpoint = values?.isUpdate;
                formValues = values;
                delete formValues["connect"];
                delete formValues["isUpdate"];
                localStorage.removeItem("twitter_values");
            }
        }
        else if (blockTitle == "tiktok") {
            if (values?.connect == 1) {
                formValues = {
                    block: blockName,
                    project: slug,
                    connect: 1,
                    end_point: end_point
                }
                const valueToStore = { ...values, ...formValues, blockName: blockTitle, isUpdate }
                localStorage.setItem("tiktok_values", JSON.stringify(valueToStore))
            }
            else if (values?.connect == 0 && values?.callback == 1) {
                endpoint = values?.isUpdate;
                formValues = values;
                delete formValues["connect"];
                delete formValues["isUpdate"];
                // localStorage.removeItem("tiktok_values");
            }
        } else if (blockTitle == "instagram") {
            if (values?.connect == 1) {
                formValues = {
                    block: blockName,
                    project: slug,
                    connect: 1,
                    end_point: end_point
                }
                const valueToStore = { ...values, ...formValues, blockName: blockTitle, isUpdate }
                localStorage.setItem("instagram_values", JSON.stringify(valueToStore))
            }
            else if (values?.connect == 0 && values?.callback == 1) {
                endpoint = values?.isUpdate;
                formValues = values;
                delete formValues["connect"];
                delete formValues["isUpdate"];
            }
        }
        else {
            formValues = {
                block: blockName,
                project: slug,
                ...values
            }
        };

        delete formValues?.image_url;
        delete formValues?.show;
        if (!formValues?.imageData) formValues?.imageData;

        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {

                // console.log("Object", key);
                if (key == "imageData") {
                    if (formValues[key]) formValues[key] = new File([formValues[key]], "cover.png")

                }
                formdata.append(key, formValues[key]);
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        // console.log("API URL", apiUrl)
        fetch(apiUrl + `/project/block/${end_point ? "update" : "create"}`, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (blockTitle !== "twitter" && blockTitle !== "tiktok" && blockTitle !== "instagram") {

                    // console.log("Title")
                    if (response?.status_code == 200) {
                        setAlert({ type: 'success', title: `${values?.name ?? values?.heading ?? values?.link_title ?? ''} Block ${end_point ? "Updated" : "Added"}` });

                        // toast.success(response?.status_message);
                        callGetProject()
                        if (blockName == "link") {
                            setlinkModal({
                                show: false,
                                parent_id: null
                            })
                            setTimeout(() => {
                                dispatch({
                                    type: "SET_EDIT_LINK_MODAL", payload: {
                                        show: true,
                                        id: response?.data?.id,
                                        link_title: response?.data?.name,
                                        destination_url: JSON?.parse(response?.data?.details?.meta)?.destination_url,
                                        link_description: JSON?.parse(response?.data?.details?.meta)?.link_description,
                                        open_in_same_tab: JSON?.parse(response?.data?.details?.meta)?.open_in_same_tab,
                                        button_text: JSON?.parse(response?.data?.details?.meta)?.button_text,
                                        link_image: JSON?.parse(response?.data?.details?.meta)?.link_image,
                                    }
                                })
                            }, 200);
                        }
                        else {

                            setTimeout(() => {
                                hideLoader()
                                handleHide()
                            }, 500);
                        }
                    }
                    else {
                        if (formValues?.open_in_same_tab) {
                            setError(Object.values(response?.errors))
                            response?.errors?.link_description?.map((val) => errorList?.push(val));
                        }
                        else {
                            response?.errors?.link_description?.map((val) => errorList?.push(val));
                            response?.errors?.link_title?.map((val) => errorList?.push(val));
                            response?.errors?.destination_url?.map((val) => errorList?.push(val));
                            setError(errorList)
                        }

                        // console.log("Error List", errorList)
                        if (errorList.length !== 0) {

                            // console.log("Error List", errorList.length)
                            errorList?.map((err) => {
                                return (
                                    // toast.error(err)
                                    setAlert({ type: 'error', title: response?.status_message })
                                );
                            });
                        }
                        else {
                            setAlert({ type: 'error', title: response?.status_message })
                            // toast.error(response?.status_message)
                        }
                        hideLoader()
                    }
                }
                else {
                    if (response?.status_code == 200) {
                        // toast.success(response?.status_message);
                        setAlert({ type: 'success', title: response?.status_message });
                        if (values?.connect == 1) {
                            setTimeout(() => {
                                localStorage.setItem("last_activity", location.pathname);
                                location.href = response?.data?.oauth_url
                                // const popup_window = window.open(
                                //     // `http://localhost:3000/tiktok-calback?code=n3gNWsWkYP_3f3YpazT3g45FaDfyf0p_pFuZnwripvXMZgnMLS4ryJHUr0XhEb7dtrZcC514QV03KjBHBBzsyAuWct0YAvZtbrhJthhdxT0%2A3%215871&scopes=user.info.basic%2Cvideo.list&state=1666709441423`
                                //     response?.data?.oauth_url
                                //     ,
                                //     'targetWindow',
                                //     `toolbar=no,
                                //    location=no,
                                //    status=no,
                                //    menubar=no,
                                //    scrollbars=yes,
                                //    resizable=yes,
                                //    width=SomeSize,
                                //    height=SomeSize`)
                                // popup_window.addEventListener('close', (() => {
                                //     
                                // console.log("popup_window");
                                // }));
                            }, 1000);
                        }
                        else {
                            callGetProject()
                            setTimeout(() => {
                                hideLoader()
                                handleHide()
                            }, 1000);
                        }
                    }
                    else {
                        setAlert({ type: 'error', title: response?.status_message });
                        // toast.error(response?.status_message)
                        hideLoader()
                    }
                }
            }).catch((err) => {
                hideLoader()
            })

    }
    const handleHide = () => {
        // Reset all modal states
        Object.values(MODAL_HANDLERS).forEach(handler => {
            handler.setState(modalsConstant);
        });

        // Reset Redux states
        dispatch({ type: "RESET_EDIT_LINK_MODAL" });
        dispatch({ type: "RESET_PROFILE_PIC_MODAL" });
        dispatch({ type: "RESET_EDITLINK_PROFILE_PIC_MODAL" });

        history.push(basePath + "/page-editor");
    }

    const handleStatus = (e, val) => {
        let status = val?.is_enabled ? 0 : 1;
        showLoader();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData()
        formdata.append("block", val?.id)
        formdata.append("project", slug)
        formdata.append("status", status)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formdata
        };
        fetch(apiUrl + "/project/block/status", requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    const index = blockList.findIndex(block => block?.id === val?.id);
                    if (index !== -1) {
                        const updatedBlockList = [...blockList];
                        updatedBlockList[index] = {
                            ...updatedBlockList[index],
                            is_enabled: !updatedBlockList[index]?.is_enabled
                        };

                        dispatch({ type: "SET_BLOCK_SETTING", payload: updatedBlockList });
                    }
                    setAlert({ type: 'success', title: `Block ${status == 1 ? 'Enabled' : 'Disabled'}` });
                }
                hideLoader()
            }).catch((err) => { })
    }
    const openChildModal = (val) => {
        const hasLinkChild = val.child_links.some((child) => child.type === "link");
        if (hasLinkChild) {
            setlinkModal({
                show: !linkModal?.show, parent_id: val?.id
            })
        } else {
            const hasProductChild = val.child_links.some((child) => child.type === "product");
            if (hasProductChild) {
                setproductModal({
                    show: !productModal?.show,
                    parent_id: val?.id,
                    from: 'folder_block'
                })
            } else {
                const hasMeetingChild = val.child_links.some((child) => child.type === "meeting");
                if (hasMeetingChild) {
                    setcalendarProductModal({
                        show: !calendarProductModal?.show,
                        parent_id: val?.id,
                        from: 'folder_block'
                    })
                } else {
                    setBlockModal({
                        show: !blockModal?.show,
                        parent_id: val?.id,
                        from: 'folder_block'
                    })
                }
            }
        }
    }
    const renderBlock = (block, isChild = false) => {
        const isFolder = block.type === "folder";
        if (isFolder || (block?.parent_id && CHILDREN_BLOCK_TYPES?.includes(block?.type))) {
            let destination_url = null;
            if (block?.details?.meta) {
                destination_url = JSON.parse(block?.details?.meta)?.destination_url;
            }

            return (
                <div className={`content-block-main-folder block ${block?.parent_id && 'nested-block'}`} data-type={block?.type} data-blockid={block.id} key={block.id}>
                    <div className={`content-block-main ${block?.parent_id && 'nested-block'} ${block.is_enabled == 0 && 'disabled pe-none'}`} data-type={block?.type} data-blockid={block.id} key={block.id}>
                        <div className="d-flex align-items-center gap-3 first-half">
                            {draggableIcon()}
                            <img width={30} src={icons[block?.type]} alt="" />
                            <div className="d-flex align-items-start justify-content-center flex-column">
                                <div className="content-block-main-text text-truncate">{block.name}</div>
                                {block?.type == 'link' && destination_url && (
                                    <small className="content-block-main-desc text-muted-light text-truncate">
                                        {destination_url}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="d-flex align-items-center gap-1 second-half">
                            {isFolder ? (
                                <>
                                    <button className="btn-ededed " data-bs-toggle="collapse" href={`#collapseExample-${block?.id}`} role="button" aria-expanded="false" aria-controls={`collapseExample-${block?.id}`}>
                                        <img src={darkChevronUpIcon} className="chevron-icon" width="9px" alt="" />
                                    </button>
                                    <div className="d-flex align-items-center">
                                        <button className="blue-btn"
                                            onClick={() => openChildModal(block)}>
                                            Add Block
                                            <div className='blue-badge'>
                                                <img src={plusIcon} alt="" width="9px" />
                                            </div>
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <span className="btn-ededed pe-auto">
                                        <img src={visibleIcon} width={12} height={15} alt="" />
                                        <span className='fs-12px ms-2'>{block.clicks}</span>
                                    </span>
                                    <button className="btn-ededed pe-auto" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={gearPageIcon} width={18} alt="" />
                                    </button>
                                    <ul className="dropdown-menu pe-auto" aria-labelledby="table-action-dropdown">
                                        <li>
                                            <span onClick={(e) => editModal(e, block)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                                <img className='opacity-100' src={editIcon} alt="" />
                                                <span className="me-2">Edit Block</span>
                                            </span>
                                        </li>
                                        <li>
                                            <span onClick={(e) => handleStatus(e, block)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                                <img src={block.is_enabled ? closedEyeIcon : openedEyeIcon} alt="" />
                                                <span className="me-5">{block.is_enabled ? 'Hide Block' : 'Un-hide Block'} </span>
                                            </span>
                                        </li>
                                        <li>
                                            <span onClick={() => duplicateBlock(block?.id)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                                <img width={15} height={14} src={duplicateIcon} alt="" />
                                                <span className="me-5">Duplicate Block </span>
                                            </span>
                                        </li>
                                        <li>
                                            <span onClick={() => onDelete(block?.id)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                                <img width={15} height={14} src={trashIcon} alt="" />
                                                <span className="me-5">Delete Block </span>
                                            </span>
                                        </li>
                                    </ul>
                                </>
                            )}
                        </div>
                    </div>
                    <>
                        {isFolder && block.child_links && block.child_links.length > 0 && (
                            <div id={`collapseExample-${block?.id}`} data-folderType={block.child_links[0]?.type || 'any'} data-folderId={block.id} className='nested-sortable collapse nested-folder-blocks content-block-children-main nested-block'>
                                {block.child_links.map(childBlock => renderBlock(childBlock, true))}
                            </div>
                        )}
                    </>
                </div>
            );
        } else if (!block?.parent_id) {
            let destination_url = null;
            if (block?.type == 'link' && block?.details?.meta) {
                destination_url = JSON.parse(block?.details?.meta)?.destination_url;
            }
            return (
                <div className={`content-block-main ${block.is_enabled == 0 ? 'disabled pe-none' : 'block'} ${CHILDREN_BLOCK_TYPES?.includes(block?.type) ? 'nested-block' : ''}`} data-blockid={block.id} data-type={block?.type} key={block.id}>
                    <div className='d-flex gap-3 align-items-center first-half'>
                        {draggableIcon()}
                        <img width={30} height={30} src={icons[block.type]} alt="" />
                        <div className="d-flex align-items-start justify-content-center flex-column">
                            <span className='content-block-main-text text-truncate'>{block.name ?? JSON.parse(block.details?.meta)?.text}</span>
                            {destination_url && <small className="content-block-main-desc text-muted-light text-truncate">{destination_url}</small>}
                        </div>

                    </div>
                    <div className='d-flex align-items-center gap-1 second-half'>
                        {block.clicks ? (
                            <span className="btn-ededed">
                                <img src={visibleIcon} width={12} height={18} alt="" />
                                <span className='fs-12px ms-2'>{block.clicks}</span>
                            </span>
                        ) : null}
                        <button className="btn-ededed pe-auto" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={gearPageIcon} width={18} alt="" />
                        </button>
                        <ul className="dropdown-menu pe-auto" aria-labelledby="table-action-dropdown">
                            <li>
                                <span onClick={(e) => editModal(e, block)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                    <img className='opacity-100' src={editIcon} alt="" />
                                    <span className="me-2">Edit Block</span>
                                </span>
                            </li>
                            <li>
                                <span onClick={(e) => handleStatus(e, block)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                    <img src={block.is_enabled ? closedEyeIcon : openedEyeIcon} alt="" />
                                    <span className="me-5">{block.is_enabled ? 'Hide Block' : 'Un-hide Block'} </span>
                                </span>
                            </li>
                            <li>
                                <span onClick={() => duplicateBlock(block?.id)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                    <img width={15} height={14} src={duplicateIcon} alt="" />
                                    <span className="me-5">Duplicate Block </span>
                                </span>
                            </li>
                            <li>
                                <span onClick={() => onDelete(block?.id)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                    <img width={15} height={14} src={trashIcon} alt="" />
                                    <span className="me-5">Delete Block </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        }
    };
    const nestedLinksSortables = (nestedSortables) => {
        for (let i = 0; i < nestedSortables.length; i++) {
            (function (index) {
                new Sortable(nestedSortables[index], {
                    handle: '#drag',
                    group: {
                        name: 'nested',
                        put: true,
                        pull: true,
                    },
                    animation: 150,
                    fallbackOnBody: true,
                    swapThreshold: 0.65,
                    onAdd: (evt) => {
                        let dragIn = evt?.to?.classList?.contains('nested-folder-blocks');
                        let mainDiv;
                        if (dragIn) {
                            // mainDiv = document.querySelectorAll('.nested-folder-blocks')[0];
                            mainDiv = evt?.to;
                        } else {
                            mainDiv = evt?.from;
                        }
                        let hasNotChildDiv = mainDiv?.children?.length === 0 ? true : false;
                        let isLink = (!dragIn) || (evt?.item?.dataset?.type == mainDiv?.dataset?.foldertype && !hasNotChildDiv) || ((mainDiv.dataset?.foldertype == 'any' || hasNotChildDiv) && (CHILDREN_BLOCK_TYPES?.includes(evt?.item?.dataset?.type)));
                        if (!isLink) {
                            evt?.from?.insertBefore(evt?.item, evt?.from?.childNodes[evt?.oldIndex]);
                            return;
                        } else {
                            let folders = [];
                            let nestedBlocks = mainDiv.querySelectorAll('.nested-block');
                            let folder_id = mainDiv.dataset?.folderid;

                            nestedBlocks?.forEach(nestedBlock => {
                                const dataId = nestedBlock?.dataset?.blockid ?? null;
                                folders?.push({ dataId });
                            });
                            $?.ajax({
                                type: 'POST',
                                url: `${apiUrl}/project/nested-sort`,
                                data: {
                                    folder_id: folder_id,
                                    folders: folders,
                                    project: localStorage?.slug,
                                },
                                headers: {
                                    'Authorization': `Bearer ${localStorage?.getItem("token")}`
                                },
                                dataType: 'json',
                                success: () => {
                                    isLink = false;
                                    folders = [];
                                    setIframeURL(protocol + '' + pageUrl + '/' + localStorage.slug + '?iframe=1&v=' + Math.random(100));
                                    console.log("Nested sorting successful");
                                }
                            });
                        }
                    },
                });
            })(i);
        }
    };
    const sendDragRequest = (links) => {
        // let links = []
        // for (let index = 0; index < list.length; index++) {
        //     // const element = list[index];
        //     let link = {
        //         link_id: list[index].id,
        //         order_no: index
        //     };
        //     links.push(link)

        // }
        $.ajax({
            type: 'POST',
            url: `${apiUrl}/project/sort`,
            data: {
                request_type: 'order',
                links,
                project: localStorage.slug,
            },
            headers: {
                'Authorization': `Bearer ${localStorage?.getItem("token")}`
            },
            dataType: 'json',
            success: (response) => {
                // 
                // console.log("response", response)
                setTimeout(() => {
                    setIframeURL(protocol + '' + pageUrl + '/' + localStorage.slug + '?iframe=1&v=' + Math.random(100));
                }, 500);
            }
        });
    }
    var nestedSortables = [].slice.call(document.querySelectorAll('.nested-sortable'));
    const folderLinkSortable = () => {
        const elements = document?.querySelectorAll('.nested-folder-blocks');

        if (elements && elements.length > 0) {
            elements.forEach((el) => {
                if (el) {
                    Sortable.create(el, {
                        animation: 150,
                        handle: '#drag',
                        onUpdate: (event) => {
                            let folders = [];
                            document.querySelectorAll('.nested-folder-blocks > .nested-block').forEach((elm, i) => {
                                let folder = {
                                    link_id: elm.dataset.blockid,
                                    order_no: i
                                };
                                folders.push(folder);
                            });
                            console.log("folders", folders)
                            if (folders.length > 0) {
                                sendDragRequest(folders);
                            }
                        },
                    });
                }
            });
        }
    };
    useEffect(() => {
        dispatch({ type: "RESET_UPLOAD_IMAGE" })
        $("body").append(script_tag)

    }, [])
    useEffect(() => {
        nestedSortables = [].slice.call(document.querySelectorAll('.nested-sortable'));
        if (typeof Sortable !== 'undefined') {
            folderSortable(setIframeURL)
            folderLinkSortable();
            if (nestedSortables?.length > 1) {
                nestedLinksSortables(nestedSortables);
            }
        }
    }, [blockList, nestedSortables]);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
            setIframeURL(protocol + '' + pageUrl + '/' + slug + '?iframe=1');
        }, 1500);
    }, [blockList]);
    const onDelete = (id) => setconfirmationModalShow({ show: true, title: 'Delete Block', message: "Are you sure you want to delete this block?", id: id, callBackTo: "delete" })
    const duplicateBlock = (id) => {
        setconfirmationModalShow({
            show: true, title: 'Duplicate Block', message: "Are you sure you want to duplicate this block?", id: id, callBackTo: "duplicate"
        })
    }
    const confirmedDeleteBlock = (id) => {
        showLoader()
        if (id) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            var requestOptions = {
            };
            const formdata = new FormData();
            var api = `${apiUrl}/project/block/`
            if (confirmationModalShow?.callBackTo == "delete") {
                requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow'
                }
                api = `${apiUrl}/project/block/delete/${id}`
            }
            else {
                api = `${apiUrl}/project/block/duplicate`

                formdata.append("project", slug)
                formdata.append("block", id)

                requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: 'follow',
                    body: formdata
                }
            }

            // setconfirmationModalShow({ show: false, text: null, id: null })
            fetch(api, requestOptions)
                .then(async (res) => {
                    const response = await res?.json();
                    if (response?.status_code == 200) {
                        if (confirmationModalShow?.callBackTo == "delete") {
                            // $(`[data-folder='${confirmationModalShow?.id}']`)?.addClass('d-none');
                            // $(`[data-folder='${confirmationModalShow?.id}']`)?.remove();
                            // setIframeURL(protocol + '' + pageUrl + '/' + slug + '?iframe=1');
                            // setIframeIndex(iframeIndex + 1);
                            const deletedBlock = blockList.find(block => block.id === confirmationModalShow?.id);
                            setAlert({ type: 'success', title: `${deletedBlock?.name} ${deletedBlock?.name !== 'Text Block' ? 'Block' : ''} Deleted` });
                            const updatedBlockList = blockList.filter(block => block.id !== confirmationModalShow?.id);
                            dispatch({ type: 'SET_BLOCK_SETTING', payload: updatedBlockList });
                            hideLoader()
                         
                        }else {
                            const duplicatedBlock = blockList.find(block => block.id === confirmationModalShow?.id);
                            setAlert({ type: 'success', title: `${duplicatedBlock?.name} Block Duplicated` });
                            callGetProject(true);
                        }
                    }
                    else {
                        setAlert({ type: 'error', title: `${response?.status_message}` });
                        // toast.error(response?.status_message)
                        hideLoader()
                    }
                    setconfirmationModalShow({ show: false, text: null, id: null })
                })
                .catch(() => {
                    setconfirmationModalShow({ show: false, text: null, id: null })
                    hideLoader()
                })
        }

    }
    const handleCloseConfirmation = () => {
        setconfirmationModalShow({ show: false, text: null, id: null })
    };
    const handleClose = () => {
        setBlockModal({ show: false, from: "folder_block" })
    };
    return (
        <>
            <div className='blocks-content new-page-editor-section'>
                <div className='page-editor-heading'>
                    Blocks
                </div>
                {loading ?
                    <div className="editor-main" >
                        {
                            Array(5).map(() => (
                                <Skeleton height={80}
                                    className="mt-2"
                                    borderRadius="20px"
                                    highlightColor="#ffffff"
                                    duration={4}
                                />
                            ))
                        }
                    </div>
                    :
                    <div key={blockList?.length} className='editor-main nested-sortable d-flex flex-column gap-10px scrollbar-on-hover'>
                        {blockList?.length > 0 && blockList.map(block => renderBlock(block))}
                    </div>
                }
                <button className="blue-btn"
                    onClick={() => showModal("newBlockModal")}>
                    <img src={plusIcon} alt="" width={10} />
                    <span>Add block</span>
                </button>
            </div>
            {state?.congratsOpen && <CongratsModal show={true} />}

            {blockModal?.show && <BlockEditor show={blockModal?.show} slug={slug} handleSubmit={handleSubmit} handleClose={handleClose} blockModal={blockModal} setBlockModal={setBlockModal} from="folder_block" returnIcon={returnIcon} />}

            {editLinkModal?.show ? <EditLinkModal show={true} returnIcon={returnIcon} editLinkModal={editLinkModal} handleSubmit={handleSubmit} error={error} /> : null}

            {emailWidgetModal?.show ? <CreateEmailWidgetModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setemailWidgetModal={setemailWidgetModal} emailWidgetModal={emailWidgetModal} /> : null}

            {textBlockModal?.show ? <CreateTextBlockModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} settextBlockModal={settextBlockModal} textBlockModal={textBlockModal} /> : null}

            {folderModal?.show ? <CreateFolderModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setfolderModal={setfolderModal} folderModal={folderModal} /> : null}

            {productModal?.show ? <CreateProductWidgetModal slug={slug} handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setproductModal={setproductModal} productModal={productModal} /> : null}
            {calendarProductModal?.show ? <CreateMeetingProductWidgetModal slug={slug} handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setcalendarProductModal={setcalendarProductModal} calendarProductModal={calendarProductModal} /> : null}
            {/* {calendarProductModal?.show ? <CreateLeadMagnetWidgetModal slug={slug} handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setcalendarProductModal={setcalendarProductModal} calendarProductModal={calendarProductModal} /> : null} */}

            {youtubeModal?.show ? <CreateYoutubeWidgetModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setyoutubeModal={setyoutubeModal} youtubeModal={youtubeModal} /> : null}

            {tiktokModal?.show ? <CreateTiktokWidgetModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} settiktokModal={settiktokModal} tiktokModal={tiktokModal} /> : null}
            {instagramModal?.show ? <CreateInstagramWidgetModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setinstagramModal={setinstagramModal} instagramModal={instagramModal} /> : null}

            {twitterModal?.show ? <CreateTwitterWidgetModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} settwitterModal={settwitterModal} twitterModal={twitterModal} /> : null}
            {contactModal?.show ? <CreateContactModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setcontactModal={setcontactModal} contactModal={contactModal} /> : null}
            {zaapPostsModal?.show ? <CreateZaapPostsModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setzaapPostsModal={setzaapPostsModal} zaapPostsModal={zaapPostsModal} /> : null}

            {embedBlockModal?.show &&
                <CreateEmbedBlockModal
                    show={embedBlockModal?.show} embedBlockModal={embedBlockModal} setembedBlockModal={setembedBlockModal} handleHide={handleHide} returnIcon={returnIcon} handleSubmit={handleSubmit} error={error} />}
            {spotifySyncBlockModal?.show &&
                <CreateSpotifySyncBlockModal
                    show={spotifySyncBlockModal?.show} embedBlockModal={spotifySyncBlockModal} setembedBlockModal={setSpotifySyncBlockModal} handleHide={handleHide} returnIcon={returnIcon} handleSubmit={handleSubmit} error={error} />}

            {leadMagnetModal?.show &&
                <CreateLeadMagnetWidgetModal
                    show={leadMagnetModal?.show} leadMagnetModal={leadMagnetModal} setLeadMagnetModal={setLeadMagnetModal} handleHide={handleHide} returnIcon={returnIcon} handleSubmit={handleSubmit} error={error} />}
            {linkModal?.show && <CreateLinkModal show={linkModal?.show} parent_id={linkModal?.parent_id} basePath={basePath} mainPath={mainPath} setshowConfirmation={setconfirmationLinkModal} history={history} handleHide={handleHide} returnIcon={returnIcon} handleSubmit={handleSubmit} error={error} from={"main"} />}
            {bannerModal.show && <UploadImageBanner basePath={basePath} handleHide={handleHide} returnIcon={returnIcon} slug={slug} />}
            {confirmationModalShow?.show &&
            <NewConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} callback={confirmedDeleteBlock}/>
            }
            {/* <CroppieModal show={showCroppieModal} handleHide={handleHide} croppieClose={croppieClose} croppedImage={croppedImage} /> */}
        </>
    )
}

export default Content