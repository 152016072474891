import React from 'react'
import CustomDropdown from '../common/customDropdown'

const DesignComponents = ({dropDownOptions, handleSelect, desc, label, defaultOption = null, reducerKey=null, dropdownLabel="label", valueKey="value"}) => {
    return (
        <div className='d-flex align-items-start flex-column gap-10px'>
            <div className='theme-type-div-heading'>
                {label}
            </div>
            <div className='d-flex justify-content-between align-center-center w-100 gap-2'>
                <CustomDropdown
                    options={dropDownOptions}
                    defaultOption={defaultOption}
                    onSelect={handleSelect}
                    reducerKey={reducerKey}
                    dropdownLabel={dropdownLabel}
                    valueKey={valueKey}
                />
                <span className='btn-ededed fs-12px '>
                    {desc}
                </span>

            </div>
        </div>
    )
}

export default DesignComponents