import custom_theme_light from "../../../assets/img/custom_theme_light.svg";
import custom_theme_dark from "../../../assets/img/custom_theme_dark.svg";
// import custom_theme_colored from "../../../assets/img/custom_theme_colored.svg";
import custom_theme_colored from "../../../assets/img/color-picker-pencil.svg";
const SelectedThemeInput = ({data_type,color,themeType}) => {
    return (
        <div className={`d-flex align-items-center justify-content-between zaap-normal-text color-picker-div ${data_type == themeType && 'active'}`}>
            {/* <img data-toggle="tooltip" data-original-title="Light" src={custom_theme_light} width="40px" className="text-color-img " data-color="#ffffff" data-type={data_type} alt="" />
            <img data-toggle="tooltip" data-original-title="Dark" src={custom_theme_dark} width="40px" className="text-color-img " data-color="#1E242A" data-type={data_type} alt="" /> */}
            <div className="d-flex justify-content-start align-items-center" style={{gap:"10px"}}>
            <span className={`data-${data_type}-background`} style={{background:color}}></span>
            <div>{color}</div>
            </div>
            <img className="me-2 text-color-img" data-toggle="tooltip" data-original-title="Colored"  src={custom_theme_colored} data-color="" data-type={data_type} alt="" />
        </div>
    )
}
export default SelectedThemeInput