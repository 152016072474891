import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import DetailsPage from './details-page'
import dragIcon from "../../../../assets/img/page/drag-icon.svg";
import { hideLoader, showLoader } from '../../../loader';
import { apiUrl, pageUrl, protocol } from '../../../../utils/url';
import ContentPage from './content-page';
import plusIcon from "../../../../assets/img/thin-plus-icon.svg";
import CheckoutPage from './checkout-page';
import BlockIcon from "../../../../assets/img/page/blockicon.svg";
import gearPageIcon from "../../../../assets/img/page/gear.svg";
import trashIcon from "../../../../assets/img/page/trash.svg";
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../../../services/useDebounce';
import ErrorMessage from '../../../errors/errorMessage';

function ProductTabs({count, setCount, editProduct, setIframeURL, iframeIndex, setIframeIndex, showProductsSectionModal, data, error, setError, onDelete, editModal, product_id, setProductSlug }) {
    const dispatch = useDispatch();
    const { slug } = useSelector(state => state?.slugReducer);
    // const values = useSelector(state => state?.productReducer);
    const [values, setValues] = useState({});
    const [tab, setTab] = useState();
    const [debouncedFormData, setDebouncedFormData] = useState(values);
    const productDetails = useSelector(state => state?.productDetailReducer);
    const [type, setType] = useState(null);
    const isSaving = useSelector(state => state?.productSavingReducer);
    useEffect(()=>{
        if (data?.product_type) {
            setType(data?.product_type?.toLowerCase());
        }
    },[data?.product_type])
    const draggableIcon = () => <img src={dragIcon} id="drag" className="me-3 cursor-grab" alt="" />
    const debouncedValues = useDebounce(values, 1500);
    const handleSubmit = (e,newValues,tab) => {
        e?.preventDefault();
        setValues(newValues)
        setTab(tab);
    }
    useEffect(() => {
        if (debouncedValues && count > 0) {
            submitChanges(debouncedValues,tab);
        }
    }, [debouncedValues]);
    const submitChanges = (values,tab) => {
        console.log("values",values)
        // if (count > 0) {
        dispatch({ type: "SET_PRODUCT_SAVING", payload: {show:true , type:'saving', message: "Saving..."} });
        // e?.preventDefault();
        const api = apiUrl + "/ProductFlow/update-setting";
        // showLoader()
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const formdata = new FormData();
            // for (const key in values) {
                // if (Object.hasOwnProperty.call(values, key)) {
                //     if(key == 'product_content_data'){
                //         values?.product_content_data.forEach((fileObj, index) => {
                //             // const fileBlob = new Blob([fileObj.content], { type: fileObj.type });
                //             formdata.append(`product_content_data[${index}][type]`, fileObj?.type);
                //             formdata.append(`product_content_data[${index}][content]`, fileObj.content);
                //             formdata.append(`product_content_data[${index}][name]`, fileObj?.name);
                //           });
                //     }else if(key == 'tiers'){
                //             values?.tiers.forEach((val, index) => {
                //                 // formdata.append(key, JSON.stringify(values?.tiers));
                //                 formdata.append(`tiers[${index}][type]`, val?.type);
                //                 formdata.append(`tiers[${index}][price]`, val.price);
                //                 formdata.append(`tiers[${index}][tier_name]`, val?.tier_name);
                //               });
                //     }else{
                //         formdata.append(key, values[key]);
                //     }
                // }
                Object.keys(values).forEach((key) => {
                    const value = values[key];
                    
                    // Check if the value is an array
                    if (Array.isArray(value)) {
                        value.forEach((item, index) => {
                            if (typeof item === 'object' && item !== null) {
                                // If the item is an object (like product_content_data, tiers, or checkout_question)
                                Object.keys(item).forEach((subKey) => {
                                    formdata.append(`${key}[${index}][${subKey}]`, item[subKey]);
                                });
                            } else {
                                // For arrays that are not objects
                                formdata.append(`${key}[${index}]`, item);
                            }
                        });
                    } else if (typeof value === 'object' && value !== null) {
                        // If the value is an object but not an array (in case you have nested objects)
                        Object.keys(value).forEach((subKey) => {
                            formdata.append(`${key}[${subKey}]`, value[subKey]);
                        });
                    } else {
                        // For scalar values (non-array, non-object)
                        formdata.append(key, value);
                    }
                });
            // }
            if (tab == "images") {
                if(values?.image_url){
                    formdata.delete('image_url');
                    // values?.cover_image.map((val, key) => {
                        if (values?.image_url) {
                            formdata.append("cover_image", values?.image_url);
                            // formdata.append("cover_image", new File([values?.image_url], "image.png"));
                        }
                    // });
                }
            }
            formdata.append("project", slug);
            formdata.append("tab", tab);
            formdata.append("product_id", product_id);
            const setOptions = {
                method: "POST",
                body: formdata,
                headers: myHeaders,
            }
            fetch(api, setOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    setError([])
                    setTimeout(() => {
                        // editProduct(product_id)
                        let productslug = data?.slug;
                        if (response?.data?.slug) {
                            productslug = response?.data?.slug;
                            setProductSlug(response?.data?.slug)
                        }
                        if (tab == "images") {
                            dispatch({ type: "SET_PRODUCT_IMAGES", payload:{cover_image: response?.data?.cover_images} })
                        }
                        var iframe_index = iframeIndex + 1;
                        setIframeURL(protocol + '' + pageUrl + '/' + slug + '/' + productslug  + '?iframe=1');
                        setIframeIndex(iframe_index + 1);
                        hideLoader();
                    }, 1000);
                    setTimeout(() => {
                        dispatch({ type: "SET_PRODUCT_SAVING", payload: {show:false, type:"success", message: "Saved."} });
                    }, 1100);
                    // toast.success(response?.status_message)
                }else{
                    setTimeout(() => {
                        dispatch({ type: "SET_PRODUCT_SAVING", payload: {show: false, type: "error", message: "Something went Wrong"} });
                    }, 1100);
                    setError(Object.values(response?.errors));
                    if (Object.keys(response?.errors).length > 0)
                        $(".modal-body").scrollTop(0);
                    hideLoader();
                }
            // hideLoader();
          }).catch(() => {
            setTimeout(() => {
                dispatch({ type: "SET_PRODUCT_SAVING", payload: {show: false, type: "error", message: "Something went Wrong"} });
            }, 1100);
            // hideLoader();
          });
        // }
    };
    return (
        <>
        <ErrorMessage error={error}/>
        <Tabs
            defaultActiveKey="product"
            id="justify-tab-example"
            className="mb-3 up-in-toggle main-btn-header btnTxtDark border-less"
            justify
        >
            <Tab
                eventKey="product"
                title={"Product"}
                className="product-nav-link"
            >
                <DetailsPage type={type} data={data} count={count} handleSubmit={handleSubmit} error={error} setError={setError} />
            </Tab>
            <Tab
                eventKey="content"
                className="up-in-toggle"
                title={type == 'meeting' ? 'Availability' : data?.product_type == 'membership' ? 'Membership' : 'Content'}
            >
                <ContentPage data={data} count={count} handleSubmit={handleSubmit} error={error} setError={setError} />
            </Tab>
            <Tab
                eventKey="website"
                className="up-in-toggle"
                title={"Website"}
            >
                <div className='Edit-area'>
                    <div className='product-blocks-main' id="product-blocks-main">
                        {data?.product_blocks?.length > 0 && data?.product_blocks?.map((val, index) => {
                            if (val?.type == "three_box") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">

                                                    <h5 className="editor-link-headings mb-0">Feature</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (val?.type == "horizontal") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">
                                                    <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (val?.type == "vertical") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">
                                                    <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (val?.type == "faq") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">

                                                    <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.faq_heading}</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (val?.type == "reviews") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">

                                                    <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (val?.type == "pricing") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">

                                                    <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <div className="editor-header d-flex align-items-center justify-content-start mt-20px">
                        <button className="bg-blue w-100" onClick={() => showProductsSectionModal('add_block')}>
                            <img src={plusIcon} alt="" width={10} />
                            <span>Add Section</span>
                        </button>
                    </div>
                </div>
            </Tab>
            <Tab
                eventKey="option"
                className="up-in-toggle"
                title={"Options"}
            >
                <CheckoutPage data={data} count={count} handleSubmit={handleSubmit} error={error} setError={setError} />
            </Tab>
        </Tabs>
        </>
    )
}

export default ProductTabs