import React, { Component } from "react";
import iro from "@jaames/iro";
import { hideLoader, showLoader } from "../loader";


class IroCircularColorPicker extends React.Component {
    typingTimer;                //timer identifier
    doneTypingInterval = 500;
    componentDidMount() {
        const { props } = this;
        const _this = this;
        // create a new iro color picker and pass component props to it
        this.colorPicker = new iro.ColorPicker(this.el, props);
        // call onColorChange prop whenever the color changes
        this.colorPicker.on("color:change", color => {

            if (props?.themeType.includes("header") || props?.themeType.includes("background") || props?.themeType.includes("links") || props.themeType?.includes("button_text")) {
                $(`#hex_code-${props?.themeType}`).val(color.hexString);
                // $(`.custom-theme-preview svg ${props.nthChild}`).css('fill', $(`#hex_code-${props?.themeType}`).val());

                $(`#rgb_code-${props?.themeType}`).val(color.hexString);
                $(`#hex_code-${props?.themeType}-label`).css("backgroundColor", color.hexString);
            }
            else if (props?.themeType.includes("buttons")) {
                $(`#hex_code-${props?.themeType}`).val(color.hexString);
                // $(`.custom-theme-preview svg path:nth-child(5)`).css('fill', $(`#hex_code-${props?.themeType}`).val());
                // $(`.custom-theme-preview svg path:nth-child(4)`).css('fill', $(`#hex_code-${props?.themeType}`).val());
                $(`#hex_code-${props?.themeType}-label`).css("backgroundColor", color.hexString);

                $(`#rgb_code-${props?.themeType}`).val(color.hexString);

            }
        });

        $(`#hex_code-${props?.themeType}`).keyup(function () {
            clearTimeout(this.typingTimer);
            try {

                if ($(this).val()) {
                    _this.typingTimer = setTimeout(() => {
                        _this.colorPicker.color.hexString = $(this).val();
                    }, _this.doneTypingInterval);
                }
            }
            catch { }
        });
    }


    componentDidUpdate() {
        // isolate color from the rest of the props
        const { color, ...colorPickerState } = this.props;
        // update color
        try {
            if (color) this.colorPicker.color.set(color);
            this.colorPicker.setState(colorPickerState);
        } catch { }
        // push rest of the component props to the colorPicker's state
    }

    render() {
        return (
            <>
                <div ref={el => (this.el = el)} className="mx-auto" />
                <input type="hidden" id={`rgb_code-${this?.props?.themeType}`} class="" value={this?.props?.color} name="rgb_code" required="required"/>
            </>
        );
    }
}
export default IroCircularColorPicker;