import React from 'react'
import created_at from "../../assets/img/analytics/created-at.svg"
import links from "../../assets/img/analytics/links.svg"
import link_icon from "../../assets/img/modals/link-icon.svg"
import setDateFormat from '../../utils/setDateFormat'
import Skeleton from 'react-loading-skeleton'

const LinksHeader = ({ data, loading }) => {
    return (
        <div className='link-analytics-header'>
            <div className='links-analytics-main align-items-center'>
                <div className='favicon-img'>
                    <img width={'100%'} src={links} alt="" srcset="" />
                </div>
                <div className='first-half'>
                    <div className='text-gray'>{data?.pageHeading}</div>
                    <div className='text-truncate text-dark' style={{ maxWidth: "150px" }}>{loading ?
                        <Skeleton width={181} baseColor='#FAFAFA' highlightColor='#EEEDEE' height={25} borderRadius="0.5rem" />
                        : data?.id}</div>
                </div>
            </div>
            {
                data?.created_at &&
                <div className='links-analytics-main align-items-center'>
                    <div className='favicon-img'>
                        <img width={'100%'} src={created_at} alt="" srcset="" />
                    </div>
                    <div className='first-half'>
                        <div className='text-gray'>Added on</div>
                        <div className='text-truncate text-dark' style={{ maxWidth: "150px" }}>{loading ? <Skeleton width={181} baseColor='#FAFAFA' highlightColor='#EEEDEE' height={25} borderRadius="0.5rem" />:(!data?.created_at ? null : setDateFormat(data?.created_at, false, false))}</div>
                    </div>
                </div>
            }
            {
                loading || data?.used_on ?
                <div className='links-analytics-main align-items-center'>
                    <div className='favicon-img'>
                        <img width={'100%'} src={link_icon} alt="" srcset="" />
                    </div>
                    <div className='first-half'>
                        <div className='text-gray'>{loading ? <Skeleton width={181} baseColor='#FAFAFA' highlightColor='#EEEDEE' height={25} borderRadius="0.5rem" />:data?.used_on}</div>
                        <div className='text-truncate text-dark' style={{ maxWidth: "150px" }}>{loading ? <Skeleton width={181} baseColor='#FAFAFA' highlightColor='#EEEDEE' height={25} borderRadius="0.5rem" />:data?.pageDesc}</div>
                    </div>
                </div>:null

            }
        </div>
    )
}

export default LinksHeader