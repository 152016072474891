import { Button, Modal } from "react-bootstrap"
import returnIcon from "../../assets/img/modals/return.svg";

const NewConfirmationModal = ({ confirmationModalShow, setShow, setisConfirmMessage, callback }) => {
    const confirmed = (e) => {
        e && e?.preventDefault();
        callback(confirmationModalShow?.id)

    }

    const handleClose = () => {
        setShow({ show: false, text: null, id: null, callback: null})
    }

    return (
        <Modal className="new-confirmation-modal" show={confirmationModalShow?.show} onHide={handleClose} centered>
            <Modal.Header className="borderless px-3 pb-0">
                <Modal.Title className="font-weight-500 fs-22px d-flex align-items-center gap-2">
                    {confirmationModalShow?.icon && 
                        <div className="blue-icon">
                            <img className={confirmationModalShow?.icon_class} width={confirmationModalShow?.icon_width ?? 30} src={confirmationModalShow?.icon} alt="" srcset="" />
                        </div>
                    }
                    {confirmationModalShow?.title ?? 'Confirmation Message'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0 font-weight-500 pe-5 fs-14px px-3 mt-20px">{confirmationModalShow?.message}</Modal.Body>
            <form onSubmit={confirmed}>
                <Modal.Footer className="borderless justify-content-start px-3 gap-3 mt-20px pt-0">
                    <button className="close btn btn-grey btn-semi-rounded d-flex align-items-center justify-content-center fs-12px font-weight-500 m-0" onClick={handleClose}>
                        <span>Cancel</span>
                        <small class="badge bg-light text-dark p-1 ms-2">Esc</small>
                    </button>
                    <button className="new-primary-btn m-0" type="submit" autoFocus>
                        {confirmationModalShow?.buttonText ?? 'Yes, Disconnect'}
                        <small class="primary-badge">
                            <img src={returnIcon} alt="" />
                        </small>
                    </button>
                </Modal.Footer>
            </form>
        </Modal>

    )
}
export default NewConfirmationModal