const INIT_STATE = {
    title: null,
    // image: null,
    description: null,
    link: null,
    collect_emails: true,
    verified_check: false,
    page_language: null,
    tab: "profile",
    nsfw: false,
    imageData: null,
    favicon_upload: null,
    slug: localStorage?.slug,
}
const customizeProfileReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_CUSTOMIZE_PROFILE':
            return action.payload 
        case 'RESET_CUSTOMIZE_PROFILE':
            return INIT_STATE
        default:
            return state
    }

}
export default customizeProfileReducer