import { ReactSortable } from "react-sortablejs";
import returnIcon from "../../../../assets/img/modals/return.svg";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../loader";
import { apiUrl } from "../../../../utils/url";
import FormFieldError from "../../../errors/formFieldError";
import tickIconPrimary from "../../../../assets/img/white-tick-icon.svg";
import ErrorMessage from "../../../errors/errorMessage";
import CroppieModal from "../../../croppie-modal";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import timezones from "../../timezone.json";
import calendarIcon from "../../../../assets/img/products/calendar-icon.svg";
import removeicon from "../../../../assets/img/products/removeicon.svg";
import digitalFileIcon from "../../../../assets/img/products/digitalfileicon.svg";
import visitIcon from "../../../../assets/img/products/visiticon.svg";
import imageIcon from "../../../../assets/img/image-icon.svg";
import globeIcon from "../../../../assets/img/globe.svg";
import chevron_down from "../../../../assets/img/modals/chevron-down.svg";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import dustbinWhite from "../../../../assets/img/products/dustbin-white.svg";
import googlemetIcon from "../../../../assets/img/products/googlemeticon.svg";
import customIcon from "../../../../assets/img/products/customIcon.svg";
import questionIcon from "../../../../assets/img/products/questionicon.svg";
import searchicon from "../../../../assets/img/products/searchbar.svg"
import ConnectCalendarModal from "../../../connect-calendar-modal";
import addFaqIcon from "../../../../assets/img/products/add-faq-btn.svg";
import gearPageIcon from "../../../../assets/img/page/gear.svg";
import trashIcon from "../../../../assets/img/page/trash.svg";
import ProductContentModal from "./content-modal";
import plusIcon from "../../../../assets/img/thin-plus-icon.svg";
import ConfirmationModal from "../../../confirmation-modal";
import zoomIcon from "../../../../assets/img/modals/zoom-icon.svg";
const ContentPage = ({ data , count, handleSubmit , error, setError  }) => {
    // console.log("data",data)
    const dispatch = useDispatch()
    const [content, setContent] = useState(data?.content);
    const [openCalendar, setOpenCalendar] = useState(data?.content == 'calender' ? true : false);
    const [opendigitalfile, setOpendigitalfile] = useState(data?.content == 'files' ? true : false);
    const [duration, setDuration] = useState(data?.calendar[0]?.duration ?? 15);
    const [book_within_next_day, setBook_Within_Next_Day] = useState(data?.calendar[0]?.book_within_next_day ?? 7);
    const [custom_meeting_link, setCustomMeetingLink] = useState(data?.calendar[0]?.custom_meet_link);
    const [timezone, setTimezone] = useState(data?.calendar[0]?.timezone);
    const [meeting_location, setMeetingLocation] = useState(data?.calendar[0]?.meeting_location);
    const [openVisitUrl, setOpenVisitUrl] = useState(data?.content == 'url' ? true : false);
    const [urlPop, setUrlPop] = useState(true)
    const [selectedTimezone, setSelectedTimezone] = useState(data?.calendar?.length > 0 ? data?.calendar[0]?.timezone : null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [removepop, setRemoveopop] = useState(false);
    const [content_file, setContent_File] = useState();
    const [contentText, setContentText] = useState(data?.content_file_name)
    const [content_url, setContent_Url] = useState(data?.content_url);
    const [days, setDays] = useState("Mon");
    const history  = useHistory();
    const { slug, basePath } = useSelector((state) => state?.slugReducer);
    const { meet_token, zoom_token } = useSelector((state) => state?.meetConnectedReducer);
    const [showConnectCalendarModal, setShowConnectCalendarModal] = useState(false);
    const [isMeetConnected, setIsMeetConnected] = useState({google_meet:meet_token ? true :false,zoom:zoom_token?true:false});
    const [searchInput, setSearchInput] = useState("");
    const values = useSelector(state => state?.productContentReducer);
    const [timer, setTimer] = useState(null);
    const [contentModal, setContentModal] = useState(false);
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null });
    const getDayName = (dayIndex) => {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      return days[dayIndex];
    };
    useEffect(()=>{
      if (data) {
        // setContent(data?.content);
        setOpenCalendar(values?.content == 'calender' ? true : false);
        setOpendigitalfile(values?.content == 'files' ? true : false);
        // setDuration(data?.calendar?.length > 0 ? data?.calendar[0]?.duration : 15);
        // setCustomMeetingLink(data?.calendar?.length > 0 ? data?.calendar[0]?.custom_meet_link : null)
        // setTimezone(data?.calendar?.length > 0 ? data?.calendar[0]?.timezone : null);
        // setMeetingLocation(data?.calendar?.length > 0 ? data?.calendar[0]?.meeting_location : null);
        // setOpenVisitUrl(data?.content == 'url' ? true : false);
        // setSelectedTimezone(data?.calendar?.length > 0 ? data?.calendar[0]?.timezone : null);
        // setContentText(data?.content_file_name)
        // setContent_Url(data?.content_url);
      }
    },[data])
    useEffect(()=>{
      if (count > 0) {
        changeDelay();
      }
    },[values])
    const numberOfSlots = data?.content == 'calender' && data?.availabilities_days ? data?.availabilities_days.length : 8;
    const [defaultTimeSlots, setDefaultTimeSlots] = useState(
      Array.from({ length: 7 }, (_, dayIndex) => ({
          id: dayIndex,
          day: getDayName(dayIndex),
          slots: Array.from({ length: numberOfSlots }, (_, slotIndex) => ({
              id: `${dayIndex}-${slotIndex}`,
              time: 9 + slotIndex,
          })),
          showDropdown: false, // New property
      }))
  );
  const updateStateWithAvailabilities = (availabilities) => {
    const updatedTimeSlots = defaultTimeSlots.map((daySlot) => {
      const dayAvailabilities = availabilities.filter(
        (availability) => availability.days.toLowerCase() === daySlot.day.toLowerCase()
      );
    
      if (dayAvailabilities.length > 0) {
        return {
          ...daySlot,
          slots: daySlot.slots.map((slot, slotIndex) => {
            if (dayAvailabilities[slotIndex]) {
              return {
                ...slot,
                time: dayAvailabilities[slotIndex].from,
              };
            } else {
              return {
                ...slot,
                time: false,
              };
            }
          }),
        };
      } else {
        return {
          ...daySlot,
          slots: daySlot.slots.map((slot) => ({
            ...slot,
            time: false,
          })),
        };
      }
    });
    setDefaultTimeSlots(updatedTimeSlots);
    
    };
  useEffect(() => {
    if (data?.availabilities_days) {
      updateStateWithAvailabilities(data?.availabilities_days);
    }
  }, [data?.availabilities_days]);
    function formatTime(time) {
        const formattedTime = String(time);
        
        if (formattedTime.includes(':')) {
            return formattedTime;
        } else {
            return formattedTime.padStart(2, '0') + ':00';
        }
    }
    // const handleSearchInputChange = (event) => {
    //   setSearchInput(event.target.value);
    // };
    function handleSearchInputChange() {
      const searchText = document.querySelector('.timezone-search-bar').value.toLowerCase();
      const timezoneOptions = document.querySelectorAll('.timezone-option');
      timezoneOptions.forEach(option => {
          const optionText = option.textContent.toLowerCase();
          if (optionText.includes(searchText)) {
              option.style.display = 'flex';
          } else {
              option.style.display = 'none';
          }
      });
  }
    const filteredTimezones = timezones.filter((timezone) =>
      timezone.city.toLowerCase().includes(searchInput.toLowerCase())
    );
    const handleSelectTimezone = (timezone) => {
      setSelectedTimezone(timezone);
      setTimezone(timezone.city + ' GMT' + timezone.gmtOffset);
      dispatch({ type: "SET_PRODUCT_CONTENT", payload: { ...values, timezone: timezone.city + ' GMT' + timezone.gmtOffset  } });
      setDropdownOpen(false);
    };

    // function handleCalendar() {
    //     setContent("calender");
    //     setOpendigitalfile(false);
    //     setOpenCalendar(true);
    //     setOpenVisitUrl(false);
    // }
    // function handldigital() {
    // setContent("files");

    // setOpendigitalfile(true);
    // setOpenCalendar(false);
    // setOpenVisitUrl(false);
    // }
    // function handlevisiturl() {

    // setContent("url");
    // setOpenVisitUrl(true);
    // setOpenCalendar(false);
    // setOpendigitalfile(false);
    // }
    const handleDeleteTimeSlot = (dayId, slotId) => {
    const updatedTimeSlots = defaultTimeSlots.map((day) => {
        if (day.id === dayId) {
            const updatedSlots = day.slots.filter((slot) => slot.id !== slotId);
            return { ...day, slots: updatedSlots, showDropdown: false };
        }
        return day;
    });

    setDefaultTimeSlots(updatedTimeSlots);
    dispatch({ type: "SET_PRODUCT_CONTENT", payload: { ...values, availabilities_days: updatedTimeSlots  } });
    changeDelay()
    };
    const handleNewAddTimeSlot = (dayId) => {
    const updatedTimeSlots = defaultTimeSlots.map((day) => {
        if (day.id === dayId) {
            return { ...day, showDropdown: !day.showDropdown };
        }
        return day;
    });

    setDefaultTimeSlots(updatedTimeSlots);
    dispatch({ type: "SET_PRODUCT_CONTENT", payload: { ...values, availabilities_days: updatedTimeSlots  } });
    changeDelay()
    };
    const generateTimeSlots = (duration) => {
    const timeSlots = [];
    const endTime = 23 * 60 + 59; // Convert end time to minutes
    duration = parseInt(duration);   
    for (let totalMinutes = 0; totalMinutes <= endTime; totalMinutes += duration) {
        const hour = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
        
        timeSlots.push(`${formattedHour}:${formattedMinute}`);
    }

    
            
    return timeSlots;
    };
    const isSlotDisabled = (slot, dayID) => {
    for (const day of defaultTimeSlots) {
        if (dayID == day.id) {
        for (const existingSlot of day.slots) {
        const existingTime = existingSlot.time < 10 ? `0${existingSlot.time}` : existingSlot.time;
        if (existingTime === slot || `${existingTime}:00` === slot) {
            return true;
        }
        }
    }
    }
    return false;
    };
    function handleContentfun(e) {
      // setContent_File(e.target.files[0])
      // setContentText(e.target.files[0]?.name)
      // setRemoveopop(true)
      // setUrlPop(false)
      // console.log("e.target.files[0]",e.target.files[0])
      
      // dispatch({ type: "SET_PRODUCT_CONTENT", payload: { ...values, [e.target.name]: e.target.value  } });
      dispatch({ type: "SET_PRODUCT_CONTENT", payload: { ...values, content_file: e.target.files[0],content_file_name:e.target.files[0]?.name  } });
      // changeDelay()
    }
    function handleRemoveUrl() {
        // setContentText(null);
        // setContent_File(null);
        // setRemoveopop(false);
        // setUrlPop(true);
        dispatch({ type: "SET_PRODUCT_CONTENT", payload: { ...values, content_file: null,content_file_name:null  } });
    }
    const handleDropdownSelect = (dayId, selectedTime) => {
      const updatedTimeSlots = defaultTimeSlots.map((day) => {
          if (day.id === dayId) {
              const newSlotId = `${day.id}-${day.slots.length}`;
              const newSlot = {
                  id: newSlotId,
                  time: selectedTime,
              };
              const updatedSlots = [...day.slots, newSlot];
              return { ...day, slots: updatedSlots, showDropdown: false };
          }
          return day;
      });

      setDefaultTimeSlots(updatedTimeSlots);
      dispatch({ type: "SET_PRODUCT_CONTENT", payload: { ...values, availabilities_days: updatedTimeSlots  } });
      changeDelay()
    };
    const updateContent = (e) => {
      let obj = {content:values?.content}
      if (values?.content == 'calender') {
        if ((meeting_location == 'google' && !isMeetConnected.google_meet) || (meeting_location == 'zoom' && !isMeetConnected.zoom)) {
          setShowConnectCalendarModal(true);
          return false;
        }
        obj = {
          ...obj,
          duration:values?.duration,
          book_within_next_day:values?.book_within_next_day,
          custom_meeting_link:values?.custom_meeting_link,
          timezone:values?.timezone,
          meeting_location:values?.meeting_location,
          monTimeSlot: JSON.stringify(defaultTimeSlots[1].slots),
          tueTimeSlot: JSON.stringify(defaultTimeSlots[2].slots),
          wedTimeSlot: JSON.stringify(defaultTimeSlots[3].slots),
          thurTimeSlot: JSON.stringify(defaultTimeSlots[4].slots),
          friTimeSlot: JSON.stringify(defaultTimeSlots[5].slots),
          satTimeSlot: JSON.stringify(defaultTimeSlots[6].slots),
          sunTimeSlot: JSON.stringify(defaultTimeSlots[0].slots),
        }
      }else{
       obj = {
          ...obj,
          product_content_data: values?.product_content_data
        }
      }
      // }else if(values?.content == 'files'){
      //   obj = {
      //     ...obj,
      //     content_file:values?.content_file,
      //     contentText:values?.content_file_name,
      //   }
      // }else if(values?.content == 'url'){
      //   obj = {
      //     ...obj,
      //     content_url:values?.content_url,
      //   }
      // }
      handleSubmit(e,obj,'content')
    }
    useEffect(() => {
      const handleMessage = (event) => {
  
        if (event.data && event.data.type === 'meetToken') {
  
          setIsMeetConnected(!!event.data.meetToken);
          setShowConnectCalendarModal(false);
        }
      };
      window.addEventListener('message', handleMessage);
    
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, []);
    // useEffect(()=>{
    //   setRemoveopop(data?.content_file_name ? true : false);
    //   setUrlPop(data?.content_file_name ? false : true);
    // },[data])
    const handleChange = (e) => {
      dispatch({ type: "SET_PRODUCT_CONTENT", payload: { ...values, [e.target.name]: e.target.value  } });
        // changeDelay();
    }
    function changeDelay() {
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
        }
        setTimer(
          setTimeout(() => {
            updateContent()
          }, 3000)
        );
    };
    const editModal = (e, val,index) => {
      setContentModal({show:true,val,index:index,isEdit:true});
    }
  const onDelete = (index) => setconfirmationModalShow({ show: true, text: "Are you sure you want to delete this content?", id: index, callBackTo: "delete" });
  const confirmedDeleteBlock = (index) => {
    showLoader();
      let updatedContentData = [...values?.product_content_data];
      updatedContentData.splice(index, 1);
      dispatch({ type: "SET_PRODUCT_CONTENT", payload: { ...values, product_content_data: updatedContentData } });
      // setTimeout(() => {
      hideLoader();
        setconfirmationModalShow({ show: false, text: null, id: null })
      // }, 3000);
  }
    return (
        <div class="product-pages">
            <h4 class="productHead1">Product Content</h4>
            {
              data?.product_type == 'Meeting' ?
                <div>
                  {values?.content == "calender" && (
                    <>
                      <div style={{ marginTop: 16 }}>
                        <p style={{ fontSize: 13 }}>
                        Let customers book a time slot on your calendar. For paid coaching,<br /> meetings and events.
                        {" "}
                        </p>
                      </div>
                      <div className="mb-4 product-form-group">
                      <label className="productlabels">
                        Meeting Details
                          <div className="product-labels-description">Choose the meeting duration, and timezone.</div>
                      </label>
                        <div className="d-flex flex-column gap-2  product-form-group-inputs">
                        <div className="d-flex gap-1 align-items-center">
                          <span className="break-between fs-12px w-50">
                          <span className="text-gray ">Duration</span>
                            <select name="cars" id="cars" value={duration} onChange={(a) => {setDuration(a.target.value ) } } >
                              <option value="15">15 min</option>
                              <option value="30">30 min</option>
                              <option value="45">45 min</option>
                              <option value="60">60 min</option>
                            </select>
                          </span>
                          <span className="break-between fs-12px w-50">
                          <span className="text-gray ">Book within</span>
                            <select
                              name="book_within_next_day" id="cars" value={values?.book_within_next_day} onChange={handleChange}>
                              <option value="7">7 days</option>
                              <option value="14">14 days</option>
                              <option value="30">30 days</option>
                              <option value="60">60 days</option>
                            </select>
                          </span>
                        </div>
                        <div className="timezone-select-container fs-12px">
                        <div
                          className="timezone-select-input-container"
                          onClick={() => setDropdownOpen(!dropdownOpen)}
                        >
                          {/* <img src={globeIcon} alt="" width="20px" className="globe-icon" /> */}
                          <span className="text-grey">Timezone</span>
                          <div className="selected-timezone ">
                            {selectedTimezone ? (
                              <>
                              <span>{selectedTimezone.city ? 'GMT' : selectedTimezone  } </span>&nbsp;
                                <div className="selected-timezone-city">
                                  {selectedTimezone.city ? selectedTimezone.gmtOffset +' '+ selectedTimezone.city : null}
                                </div>
                                <div className="selected-timezone-time">
                                  {selectedTimezone.formattedTime}
                                </div>
                              </>
                            ) : (
                              <div className="selected-timezone-placeholder"><span>GMT+0</span>&nbsp; Select timezone </div>
                            )}
                            <img src={chevron_down} width="15px" alt="" style={{ paddingLeft: 5, paddingTop: 2 }} />
                          </div>

                        </div>
                        {dropdownOpen && (
                          <div >
                            <div className="timezone-dropdown"style={{ overflowY: "auto", overflowX: "hidden", height: 256 }}>
                              <div className="bg-input ">
                                <img src={searchicon} alt="" width="20px" />
                                <input
                                  style={{ backgroundColor: "transparent", border: "none", outline: "none" }}
                                  type="text"
                                  placeholder="Search"
                                  onChange={handleSearchInputChange}
                                  className="timezone-search-bar "
                                />
                              </div>
                              <div  >
                                {filteredTimezones.map((timezone) => (

                                  <div
                                    key={timezone.timezone}
                                    className="timezone-option"
                                    onClick={() => handleSelectTimezone(timezone)}
                                  >
                                    <div className="timezone-option-city">{timezone.city}</div>
                                    <div className="timezone-option-time">
                                      {timezone.gmtOffset}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}

                      </div>
                        </div>
                        </div>
                      <div className="product-form-group">
                        <label className="productlabels">
                          Time Slots
                            <div className="product-labels-description">Choose the time slots that you’re available for.</div>
                        </label>
                        <div className="product-form-group-inputs">
                        <Tabs defaultActiveKey="0"
                            className="mb-3 up-in-toggle availability-tab btnTxtDark border-less"
                            justify
                            id="days-tabs">
                              {defaultTimeSlots.map((day) => ( 
                                  <Tab eventKey={day.id} title={day.day} key={day.id}>
                                      <div className="time-slot-btn-cont">
                                          {day.slots.map((slot) => (
                                              slot.time ? (
                                              <>
                                              {/* <div className="px-1" key={slot.id}>
                                                  <div className="form-group">
                                                      <label className="text-gray font-weight-500"></label> */}
                                                      <div className="time-slot-btn" key={slot.id}>
                                                      {formatTime(slot.time)}
                                                      {/*  (String(slot.time).includes(':30') || String(slot.time).includes(':00') || String(slot.time).includes(':15') || String(slot.time).includes(':45') ? String(slot.time) : String(slot.time).padStart(2, '0') + ':00') */}
                                                          {/* <small
                                                              className="badge  text-dark p-1"
                                                              onClick={() => handleDeleteTimeSlot(day.id, slot.id)}
                                                          > */}
                                                              <img src={dustbinWhite} alt="" onClick={() => handleDeleteTimeSlot(day.id, slot.id)} />
                                                          {/* </small> */}
                                                        </div>
                                                {/*  </div>
                                              </div> */}
                                              </>
                                              ) : null
                                          ))}
                                      </div>
                                      <div className="mt-3 position-relative">
                                        <button type="button" className="add-question-btn" onClick={() => handleNewAddTimeSlot(day.id)}>
                                            <span className="m-0">Add time slot</span>
                                            <img src={addFaqIcon} width="18px" alt="" />
                                        </button>
                                        {day.showDropdown && (
                                          <div className="availability-dropdown z-index-3">
                                              {generateTimeSlots(duration).map((slot) => (
                                              <div
                                                key={slot}
                                                className={`${isSlotDisabled(slot, day.id) ? 'availability-option-check' : 'availability-option'}`}
                                                onClick={isSlotDisabled(slot, day.id) ? null : () => handleDropdownSelect(day.id, slot)}
                                              >
                                                {slot}
                                              </div>
                                            ))}
                                          </div>
                                            // <select className="form-select" onChange={(e) => handleDropdownSelect(day.id, e.target.value)}>
                                            //     <option disabled selected>Select a time</option>
                                            //     <option value="9">9:00</option>
                                            //     <option value="9:30">9:30</option>
                                            //     <option value="10">10:00</option>
                                            //     <option value="10:30">10:30</option>
                                            // </select>
                                        )}
                                    </div>
                                  </Tab>
                              ))}
                          </Tabs>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: 20 }}
                      >

                        <div>
                          <div class="form-group parent-producttoggle mt-4">
                            <p className="m-1 fs-14px">Meeting Location</p>
                            <div class="up-in-toggle bg-f1f1f1 mian-google-style ">
                              <input

                                checked={values?.meeting_location == "google" ? true : false}
                                type="radio"
                                id="googlemeet"
                                name="meeting_location"
                                value={"google"}
                                onChange={handleChange}
                                style={{
                                  display: "none",
                                }}
                              />
                              <label
                                htmlFor="googlemeet"
                                style={{
                                  padding: "10px",
                                  borderRadius: "10px",
                                  fontSize: 12,
                                  whiteSpace: "nowrap",
                                  fontWeight: 500,
                                  color: "#5B5B5B",



                                }}
                              >
                                <img
                                  style={{ marginRight: 3 }}
                                  width="12px"
                                  src={googlemetIcon}
                                />
                                Google Meet
                              </label>
                              <input

                                checked={values?.meeting_location == "custom" ? true : false}
                                type="radio"
                                id="custom"
                                name="meeting_location"
                                value={"custom"}
                                onChange={handleChange}
                                style={{
                                  display: "none",
                                }}
                              />
                              <label
                                htmlFor="custom"
                                style={{
                                  padding: "10px",
                                  borderRadius: "10px",
                                  fontSize: 12,
                                  whiteSpace: "nowrap",
                                  fontWeight: 500,
                                  color: "#5B5B5B",

                                }}
                              >
                                <img
                                  style={{ marginRight: 3 }}
                                  width="12px"
                                  src={customIcon}
                                />
                                Custom
                              </label>
                              <input
                                  checked={values?.meeting_location == "zoom" ? true : false}
                                  type="radio"
                                  id="zoom"
                                  name="meeting_location"
                                  value={"zoom"}
                                  onChange={handleChange}
                                  style={{
                                    display: "none",
                                  }}
                                />
                                <label
                                  htmlFor="zoom"
                                  style={{
                                    padding: "10px",
                                    borderRadius: "10px",
                                    fontSize: 12,
                                    whiteSpace: "nowrap",
                                    fontWeight: 500,
                                    color: "#5B5B5B",
                                  }}
                                >
                                  <img
                                    style={{ marginRight: 3 }}
                                    width="12px"
                                    src={zoomIcon}
                                  />
                                  Zoom
                                </label>
                            </div>
                            {values?.meeting_location == 'custom'?
                            <>
                            <p className="mt-2 fs-12px">
                              <img
                                src={questionIcon}
                                width="24px"
                                style={{ padding: 5 }}
                              />
                              Create the meeting yourself after a customer purchases
                            </p>
                            <div className="mt-3">
                            <input
                              value={values?.custom_meeting_link}
                              onChange={handleChange}
                              type="url"
                              className="zaapGlobal-input"
                              name="custom_meeting_link"
                              placeholder="Meeting Service URL"
                            />
                            </div>
                            </>
                            : null}
                            {values?.meeting_location == 'google'?
                            <p className="ms-1 mt-1  fs-12px">Google Meet event will be created automatically after booking. Manage Google Account from settings
                            </p>:null}
                          </div>
                        </div>
                      </div>



                    </>
                  )}
                </div>
                :
                <div className='Edit-area'>
                  <div className='product-blocks-main' id="product-blocks-main">
                      {values?.product_content_data?.length > 0 && values?.product_content_data?.map((val, index) => {
                        if (val?.type == "url") {
                          return (
                            <div data-block={val?.id} className='mt-2 product-block'>
                              <div className="editor-linku bg-f3f3f3" id="folder-card">
                                <div className="d-flex align-items-center editor-link-first-half">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                  <rect width="30" height="30" rx="9" fill="#1188FF"/>
                                  <path d="M15.5355 19.8198L14.4645 20.8909C12.9856 22.3697 10.588 22.3697 9.10913 20.8909C7.63029 19.412 7.63029 17.0144 9.10913 15.5355L10.1802 14.4645M19.8198 15.5355L20.8909 14.4645C22.3697 12.9856 22.3697 10.588 20.8909 9.10913C19.412 7.63029 17.0144 7.63029 15.5355 9.10913L14.4645 10.1802M12.3492 17.6507L17.6508 12.3492" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <div className="ms-2 d-flex align-items-start justify-content-center flex-column">
                                    <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                  <div className="d-flex align-items-center">
                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val,index)}>
                                      <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} />
                                    </button>
                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                      <img src={trashIcon} onClick={() => onDelete(index)} width="14px" alt="" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        } else if (val?.type == "file") {
                          return (
                            <div data-block={val?.id} className='mt-2 product-block'>
                              <div className="editor-linku bg-f3f3f3" id="folder-card">
                                <div className="d-flex align-items-center editor-link-first-half">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                  <rect width="30" height="30" rx="9" fill="#1188FF"/>
                                  <path d="M16.5 8.18862V11.08C16.5 11.472 16.5 11.6681 16.5817 11.8178C16.6537 11.9495 16.7684 12.0566 16.9095 12.1237C17.0699 12.2 17.28 12.2 17.7 12.2H20.7979M21 13.5918V18.64C21 19.8161 21 20.4042 20.7548 20.8534C20.539 21.2485 20.1948 21.5698 19.7715 21.7711C19.2902 22 18.6601 22 17.4 22H12.6C11.3399 22 10.7098 22 10.2285 21.7711C9.80516 21.5698 9.46095 21.2485 9.24524 20.8534C9 20.4042 9 19.8161 9 18.64V11.36C9 10.1839 9 9.59583 9.24524 9.14662C9.46095 8.75148 9.80516 8.43022 10.2285 8.22889C10.7098 8 11.3399 8 12.6 8H15.0088C15.5592 8 15.8343 8 16.0933 8.05802C16.3229 8.10947 16.5423 8.19432 16.7436 8.30946C16.9707 8.43932 17.1653 8.62092 17.5544 8.98412L19.9456 11.2159C20.3347 11.5791 20.5293 11.7607 20.6684 11.9726C20.7918 12.1605 20.8827 12.3653 20.9378 12.5796C21 12.8213 21 13.0781 21 13.5918Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                  <div className="ms-2 d-flex align-items-start justify-content-center flex-column">
                                    <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                  <div className="d-flex align-items-center">
                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val,index)}>
                                      <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} />
                                    </button>
                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                      <img src={trashIcon} onClick={() => onDelete(index)} width="14px" alt="" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}
                      {values?.content == "calender" && (
                        <>
                          <div style={{ marginTop: 16 }}>
                            <p style={{ fontSize: 13 }}>
                            Let customers book a time slot on your calendar. For paid coaching,<br /> meetings and events.
                            {" "}
                            </p>
                          </div>
                          <div className="mb-4 product-form-group">
                          <label className="productlabels">
                            Meeting Details
                              <div className="product-labels-description">Choose the meeting duration, and timezone.</div>
                          </label>
                            <div className="d-flex flex-column gap-2  product-form-group-inputs">
                            <div className="d-flex gap-1 align-items-center">
                              <span className="break-between fs-12px w-50">
                              <span className="text-gray ">Duration</span>
                                <select name="cars" id="cars" value={duration} onChange={(a) => {setDuration(a.target.value ) } } >
                                  <option value="15">15 min</option>
                                  <option value="30">30 min</option>
                                  <option value="45">45 min</option>
                                  <option value="60">60 min</option>
                                </select>
                              </span>
                              <span className="break-between fs-12px w-50">
                              <span className="text-gray ">Book within</span>
                                <select
                                  name="book_within_next_day" id="cars" value={values?.book_within_next_day} onChange={handleChange}>
                                  <option value="7">7 days</option>
                                  <option value="14">14 days</option>
                                  <option value="30">30 days</option>
                                  <option value="60">60 days</option>
                                </select>
                              </span>
                            </div>
                            <div className="timezone-select-container fs-12px">
                            <div
                              className="timezone-select-input-container"
                              onClick={() => setDropdownOpen(!dropdownOpen)}
                            >
                              {/* <img src={globeIcon} alt="" width="20px" className="globe-icon" /> */}
                              <span className="text-grey">Timezone</span>
                              <div className="selected-timezone ">
                                {selectedTimezone ? (
                                  <>
                                  <span>{selectedTimezone.city ? 'GMT' : selectedTimezone  } </span>&nbsp;
                                    <div className="selected-timezone-city">
                                      {selectedTimezone.city ? selectedTimezone.gmtOffset +' '+ selectedTimezone.city : null}
                                    </div>
                                    <div className="selected-timezone-time">
                                      {selectedTimezone.formattedTime}
                                    </div>
                                  </>
                                ) : (
                                  <div className="selected-timezone-placeholder"><span>GMT+0</span>&nbsp; Select timezone </div>
                                )}
                                <img src={chevron_down} width="15px" alt="" style={{ paddingLeft: 5, paddingTop: 2 }} />
                              </div>

                            </div>
                            {dropdownOpen && (
                              <div >
                                <div className="timezone-dropdown"style={{ overflowY: "auto", overflowX: "hidden", height: 256 }}>
                                  <div className="bg-input ">
                                    <img src={searchicon} alt="" width="20px" />
                                    <input
                                      style={{ backgroundColor: "transparent", border: "none", outline: "none" }}
                                      type="text"
                                      placeholder="Search"
                                      onChange={handleSearchInputChange}
                                      className="timezone-search-bar "
                                    />
                                  </div>
                                  <div  >
                                    {filteredTimezones.map((timezone) => (

                                      <div
                                        key={timezone.timezone}
                                        className="timezone-option"
                                        onClick={() => handleSelectTimezone(timezone)}
                                      >
                                        <div className="timezone-option-city">{timezone.city}</div>
                                        <div className="timezone-option-time">
                                          {timezone.gmtOffset}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}

                          </div>
                            </div>
                            </div>
                          <div className="product-form-group">
                            <label className="productlabels">
                              Time Slots
                                <div className="product-labels-description">Choose the time slots that you’re available for.</div>
                            </label>
                            <div className="product-form-group-inputs">
                            <Tabs defaultActiveKey="0"
                                className="mb-3 up-in-toggle availability-tab btnTxtDark border-less"
                                justify
                                id="days-tabs">
                                  {defaultTimeSlots.map((day) => ( 
                                      <Tab eventKey={day.id} title={day.day} key={day.id}>
                                          <div className="time-slot-btn-cont">
                                              {day.slots.map((slot) => (
                                                  slot.time ? (
                                                  <>
                                                  {/* <div className="px-1" key={slot.id}>
                                                      <div className="form-group">
                                                          <label className="text-gray font-weight-500"></label> */}
                                                          <div className="time-slot-btn" key={slot.id}>
                                                          {formatTime(slot.time)}
                                                          {/*  (String(slot.time).includes(':30') || String(slot.time).includes(':00') || String(slot.time).includes(':15') || String(slot.time).includes(':45') ? String(slot.time) : String(slot.time).padStart(2, '0') + ':00') */}
                                                              {/* <small
                                                                  className="badge  text-dark p-1"
                                                                  onClick={() => handleDeleteTimeSlot(day.id, slot.id)}
                                                              > */}
                                                                  <img src={dustbinWhite} alt="" onClick={() => handleDeleteTimeSlot(day.id, slot.id)} />
                                                              {/* </small> */}
                                                            </div>
                                                    {/*  </div>
                                                  </div> */}
                                                  </>
                                                  ) : null
                                              ))}
                                          </div>
                                          <div className="mt-3 position-relative">
                                            <button type="button" className="add-question-btn" onClick={() => handleNewAddTimeSlot(day.id)}>
                                                <span className="m-0">Add time slot</span>
                                                <img src={addFaqIcon} width="18px" alt="" />
                                            </button>
                                            {day.showDropdown && (
                                              <div className="availability-dropdown z-index-3">
                                                  {generateTimeSlots(duration).map((slot) => (
                                                  <div
                                                    key={slot}
                                                    className={`${isSlotDisabled(slot, day.id) ? 'availability-option-check' : 'availability-option'}`}
                                                    onClick={isSlotDisabled(slot, day.id) ? null : () => handleDropdownSelect(day.id, slot)}
                                                  >
                                                    {slot}
                                                  </div>
                                                ))}
                                              </div>
                                                // <select className="form-select" onChange={(e) => handleDropdownSelect(day.id, e.target.value)}>
                                                //     <option disabled selected>Select a time</option>
                                                //     <option value="9">9:00</option>
                                                //     <option value="9:30">9:30</option>
                                                //     <option value="10">10:00</option>
                                                //     <option value="10:30">10:30</option>
                                                // </select>
                                            )}
                                        </div>
                                      </Tab>
                                  ))}
                              </Tabs>
                            </div>
                          </div>
                          <div
                            style={{ marginTop: 20 }}
                          >

                            <div>
                              <div class="form-group parent-producttoggle mt-4">
                                <p className="m-1 fs-14px">Meeting Location</p>
                                <div class="up-in-toggle bg-f1f1f1 mian-google-style ">
                                  <input

                                    checked={values?.meeting_location == "google" ? true : false}
                                    type="radio"
                                    id="googlemeet"
                                    name="meeting_location"
                                    value={"google"}
                                    onChange={handleChange}
                                    style={{
                                      display: "none",
                                    }}
                                  />
                                  <label
                                    htmlFor="googlemeet"
                                    style={{
                                      padding: "10px",
                                      borderRadius: "10px",
                                      fontSize: 17,
                                      whiteSpace: "nowrap",
                                      fontWeight: 500,
                                      color: "#5B5B5B",



                                    }}
                                  >
                                    <img
                                      style={{ marginRight: 3 }}
                                      width="11%"
                                      src={googlemetIcon}
                                    />
                                    Google Meet
                                  </label>
                                  <input

                                    checked={values?.meeting_location == "custom" ? true : false}
                                    type="radio"
                                    id="custom"
                                    name="meeting_location"
                                    value={"custom"}
                                    onChange={handleChange}
                                    style={{
                                      display: "none",
                                    }}
                                  />
                                  <label
                                    htmlFor="custom"
                                    style={{
                                      padding: "10px",
                                      borderRadius: "10px",
                                      fontSize: 17,
                                      whiteSpace: "nowrap",
                                      fontWeight: 500,
                                      color: "#5B5B5B",

                                    }}
                                  >
                                    <img
                                      style={{ marginRight: 3 }}
                                      width="12%"
                                      src={customIcon}
                                    />
                                    Custom
                                  </label>
                                  <input
                                      checked={values?.meeting_location == "zoom" ? true : false}
                                      type="radio"
                                      id="zoom"
                                      name="meeting_location"
                                      value={"zoom"}
                                      onChange={handleChange}
                                      style={{
                                        display: "none",
                                      }}
                                    />
                                    <label
                                      htmlFor="zoom"
                                      style={{
                                        padding: "10px",
                                        borderRadius: "10px",
                                        fontSize: 12,
                                        whiteSpace: "nowrap",
                                        fontWeight: 500,
                                        color: "#5B5B5B",
                                      }}
                                    >
                                      <img
                                        style={{ marginRight: 3 }}
                                        width="12px"
                                        src={zoomIcon}
                                      />
                                      Zoom
                                    </label>
                                </div>
                                {values?.meeting_location == 'custom'?
                                <>
                                <p className="mt-2 fs-12px">
                                  <img
                                    src={questionIcon}
                                    width="24px"
                                    style={{ padding: 5 }}
                                  />
                                  Create the meeting yourself after a customer purchases
                                </p>
                                <div className="mt-3">
                                <input
                                  value={values?.custom_meeting_link}
                                  onChange={handleChange}
                                  type="url"
                                  className="zaapGlobal-input"
                                  name="custom_meeting_link"
                                  placeholder="Meeting Service URL"
                                />
                                </div>
                                </>
                                : null}
                                {values?.meeting_location == 'google'?
                                <p className="ms-1 mt-1 fs-12px">Google Meet event will be created automatically after booking. Manage Google Account from settings
                                </p>:null}
                              </div>
                            </div>
                          </div>



                        </>
                      )}
                  </div>
                  <div className="editor-header d-flex align-items-center justify-content-start gap-1 mt-20px">
                    <button className="bg-blue w-100" onClick={() => setContentModal({show:true,val:{type:"url"}})}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M8.78553 12.8198L7.71447 13.8909C6.23563 15.3697 3.83796 15.3697 2.35913 13.8909C0.880291 12.412 0.880291 10.0144 2.35913 8.53553L3.43019 7.46447M13.0698 8.53553L14.1409 7.46447C15.6197 5.98563 15.6197 3.58796 14.1409 2.10913C12.662 0.630291 10.2644 0.630291 8.78553 2.10913L7.71447 3.18019M5.59924 10.6507L10.9008 5.34923" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                      <span>Add URL</span>
                    </button>
                    <button className="bg-blue w-100" onClick={() => setContentModal({show:true,val:{type:"file"}})}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                      <path d="M8.25 1.18862V4.08C8.25 4.47204 8.25 4.66806 8.33175 4.81779C8.40365 4.94951 8.51839 5.05659 8.65951 5.1237C8.81994 5.2 9.02996 5.2 9.45 5.2H12.5479M12.75 6.59176V11.64C12.75 12.8161 12.75 13.4042 12.5048 13.8534C12.289 14.2485 11.9448 14.5698 11.5215 14.7711C11.0402 15 10.4101 15 9.15 15H4.35C3.08988 15 2.45982 15 1.97852 14.7711C1.55516 14.5698 1.21095 14.2485 0.995235 13.8534C0.75 13.4042 0.75 12.8161 0.75 11.64V4.36C0.75 3.18389 0.75 2.59583 0.995235 2.14662C1.21095 1.75148 1.55516 1.43022 1.97852 1.22889C2.45982 1 3.08988 1 4.35 1H6.75883C7.30916 1 7.58432 1 7.84327 1.05802C8.07285 1.10947 8.29233 1.19432 8.49364 1.30946C8.7207 1.43932 8.91527 1.62092 9.30442 1.98412L11.6956 4.21588C12.0847 4.57908 12.2793 4.76068 12.4184 4.9726C12.5418 5.16049 12.6327 5.36534 12.6878 5.57961C12.75 5.8213 12.75 6.07812 12.75 6.59176Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                      <span>Add File</span>
                    </button>
                  </div>
                </div>
            }
        {confirmationModalShow?.show && <ConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} callback={confirmedDeleteBlock} />}
            {contentModal?.show && <ProductContentModal show={contentModal} setShow={setContentModal}/>}
                {showConnectCalendarModal && <ConnectCalendarModal show={showConnectCalendarModal} setShow={setShowConnectCalendarModal} text={<>Connect your {values?.meeting_location == 'zoom' ? 'Zoom' : 'Google'} Account to create Meet <br/> invite links when a customer purchases.</>} TwoButtons={true}/>}
        </div >
    )
}
export default ContentPage