import { useCallback, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { hideLoader, showLoader } from "../../loader"
import { Modal } from "react-bootstrap"
import { mainPath } from "../../../pages/page-editor"
import { useDispatch, useSelector } from "react-redux";

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.89333 2.08766C3.45223 2.08766 1.47333 4.02468 1.47333 6.41411C1.47333 8.80355 3.45223 10.7406 5.89333 10.7406C8.33443 10.7406 10.3133 8.80355 10.3133 6.41411C10.3133 4.02468 8.33443 2.08766 5.89333 2.08766ZM0 6.41411C0 3.2282 2.63854 0.645508 5.89333 0.645508C9.14813 0.645508 11.7867 3.2282 11.7867 6.41411C11.7867 7.74716 11.3247 8.9746 10.549 9.95142L14.5176 13.8361C14.8053 14.1177 14.8053 14.5742 14.5176 14.8558C14.2299 15.1374 13.7634 15.1374 13.4758 14.8558L9.50719 10.9712C8.50924 11.7305 7.25523 12.1827 5.89333 12.1827C2.63854 12.1827 0 9.60003 0 6.41411Z" fill="#6B6B6B" />
    </svg>
)

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.244078 0.244078C0.569515 -0.0813592 1.09715 -0.0813592 1.42259 0.244078L5 3.82149L8.57741 0.244078C8.90285 -0.0813592 9.43049 -0.0813592 9.75592 0.244078C10.0814 0.569515 10.0814 1.09715 9.75592 1.42259L6.17851 5L9.75592 8.57741C10.0814 8.90285 10.0814 9.43049 9.75592 9.75592C9.43049 10.0814 8.90285 10.0814 8.57741 9.75592L5 6.17851L1.42259 9.75592C1.09715 10.0814 0.569515 10.0814 0.244078 9.75592C-0.0813592 9.43049 -0.0813592 8.90285 0.244078 8.57741L3.82149 5L0.244078 1.42259C-0.0813592 1.09715 -0.0813592 0.569515 0.244078 0.244078Z" fill="#333333" />
    </svg>
)

const SocialBlock = ({ data, setModal }) => {
    const {id, ...modalData} = data;
    const socialData = {social_id:id}
    return (
        <>
        <div className="social-block-cont" onClick={() => setModal({show:true, ...modalData, title:'Add '+data?.title,data:socialData})}>
            <div className="socials-svg" id="drag" dangerouslySetInnerHTML={{ __html: data?.svg }} />
            <div>{data?.title}</div>
        </div>
        </>
    )
}

const SocialModal = ({ handleClose, show, blocks, setModal }) => {
    const history = useHistory()
    const basePath = useSelector(state => state?.slugReducer?.basePath)
    const isPro = useSelector(state => state?.proReducer)
    const [searchQuery, setSearchQuery] = useState("")


    const handleSearch = useCallback((e) => {
        setSearchQuery(e?.target?.value?.toLowerCase())
    }, [])

    const filteredBlocks = useMemo(() => {
        if (!searchQuery) return blocks
        return blocks.filter(block =>
            block?.name?.toLowerCase()?.includes(searchQuery) ||
            block?.title?.toLowerCase()?.includes(searchQuery)
        )
    }, [blocks, searchQuery])

    return (
        <Modal centered show={show} onHide={handleClose} size="lg" id="socialModal">
            <div className="modal-dialog-centered modal-mlarge">
                <div className="modal-content p-0" style={{ borderRadius: "30px" }}>
                    <div className="modal-body p-0">
                        <div className="add-block-modal-body-main">
                            <div className="mb-4 w-100 h-100">
                                <div className="new-block-main-div">
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <div className="d-flex flex-column mt-20px w-50">
                                            <div className="fs-18px font-weight-500">Social Icons</div>
                                            <div className="new-input-area zaapGlobal-input mt-15px">
                                                <span className="input-prefix">
                                                    <SearchIcon />
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder="Search Blocks..."
                                                    value={searchQuery}
                                                    className="p-0"
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </div>
                                        <button type="button" onClick={handleClose} className="btn-ededed">
                                            <CloseIcon />
                                        </button>
                                    </div>
                                   
                                    <div className="mt-30px d-flex align-items-center gap-3 flex-wrap">
                                        {filteredBlocks.length > 0 ? (
                                            filteredBlocks.map((block, index) => (
                                                <>
                                                <SocialBlock 
                                                    key={index}
                                                    data={block}
                                                    setModal={setModal}
                                                />
                                                </>
                                            ))
                                        ) : (
                                            <div className="text-left">No blocks found</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SocialModal