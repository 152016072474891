import React, { useEffect, useRef, useState } from 'react';
import NewSideBar from '../components/side-bar/new-sidebar';
import PageHeader from '../components/page-header';
import sendIcon from '../assets/img/send-icon.svg';
import chatIcon from '../assets/img/chat-icon.svg';
import AutoResizeTextarea from '../components/common/ResizeTextarea';

const SupportChatbot = () => {
    const textareaRef = useRef(null);
    const chatContainerRef = useRef(null);
    const [value, setValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [messages, setMessages] = useState([]);
    const [showScrollButton, setShowScrollButton] = useState(false);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!value.trim() || isLoading) return;
        // Show loader
        setIsLoading(true);
        // Add user message
        const newMessage = { id: Date.now(), text: value, sender: 'user' };
        setMessages((prev) => [...prev, newMessage]);

        setValue('');
        // Simulate AI response delay
        setTimeout(() => {
            scrollToBottom()
            simulateAIResponse();
        }, 2500);
    };

    const simulateAIResponse = () => {
        const aiResponse = {
            id: Date.now() + 1,
            text: "That's a great question! I'm here to assist you.",
            sender: 'ai',
        };

        const typingSpeed = 25; // Milliseconds per character
        const responseText = aiResponse.text;
        let currentText = "";

        // Add a placeholder AI message
        setMessages((prev) => [...prev, { ...aiResponse, text: "" }]);

        const typeText = (index) => {
            if (index < responseText.length) {
                currentText += responseText[index];
                setMessages((prev) =>
                    prev.map((msg) =>
                        msg.id === aiResponse.id
                            ? { ...msg, text: currentText }
                            : msg
                    )
                );
                setTimeout(() => typeText(index + 1), typingSpeed);
            }
        };

        // Stop loader before typing starts
        setIsLoading(false);

        setTimeout(() => {
            typeText(0); // Start typing
        }, 500); // Sync with fade-in animation duration
    };

    const handlePause = () => {
        setIsPaused(true);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Prevent line break
            handleSubmit(e); // Trigger form submission
        }
    };

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight + 50;
        }
    };

    useEffect(() => {
        scrollToBottom()
    }, [messages, isLoading]);

    useEffect(() => {
        const checkScroll = () => {
            if (chatContainerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
                // Show scroll button if scrollbar exists and not at bottom
                setShowScrollButton(
                    scrollHeight > clientHeight &&
                    (scrollTop + clientHeight < scrollHeight - 50) // 50px buffer
                );
            }
        };

        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.addEventListener('scroll', checkScroll);
            scrollToBottom();
            checkScroll();

            return () => {
                chatContainer.removeEventListener('scroll', checkScroll);
            };
        }
    }, [messages, isLoading]);

    return (
        <div id="wrapper">
            <PageHeader />
            <div className="support-seciton-main">
                <NewSideBar />
                <div className="support-seciton">
                    <div className="affiliate-div gap-3 w-100">
                        <div>
                            <h2 className="m-0">Zaac is here to support you!</h2>
                            <p className="m-0">Zaap is our AI support agent, designed to help you with Zaap.</p>
                        </div>
                        <div>
                            <a href="https://www.zaap.ai/support" target="_blank" className="btn-grey font-weight-500 settings-button-primary me-2">
                                Report issue
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                        <path d="M1 7L7 1M7 1H3M7 1V5" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </a>
                            <a href="https://www.zaap.ai/support" target="_blank" className="blue-btn">
                                Contact Our Team
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                        <path d="M1 7L7 1M7 1H3M7 1V5" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="chat-section">
                        {
                            messages?.length > 0 &&
                            <>
                                <div ref={chatContainerRef} className={`chat-con ${messages?.length > 0 ? 'h-100' : ''}`}>

                                    <div className='chat-main-parent'>
                                        <div className='chat-main ai-chat'>
                                            Hi! My name is Zaac, the Creator Assistant. How can I help you?
                                        </div>
                                    </div>

                                    {messages.map((msg) => (
                                        <div key={msg.id} className='chat-main-parent'>
                                            <pre className={`chat-main ${msg?.sender === 'ai' ? 'ai-chat ai-response' : 'user-chat'} chat-message`}>
                                                {msg?.text}
                                            </pre>
                                        </div>
                                    ))}
                                    {isLoading && (
                                        <div className='chat-main-parent'>
                                            <div className='chat-main ps-0'>
                                                <div className='chatbot-loader'>
                                                    <svg className="pl_icon" width="240" height="240" viewBox="0 0 240 240">
                                                        <circle className="pl__ring pl__ring--a" cx="120" cy="120" r="105" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 660" strokeDashoffset="-330" strokeLinecap="round"></circle>
                                                        <circle className="pl__ring pl__ring--b" cx="120" cy="120" r="35" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 220" strokeDashoffset="-110" strokeLinecap="round"></circle>
                                                        <circle className="pl__ring pl__ring--c" cx="85" cy="120" r="70" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 440" strokeLinecap="round"></circle>
                                                        <circle className="pl__ring pl__ring--d" cx="155" cy="120" r="70" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 440" strokeLinecap="round"></circle>
                                                    </svg>
                                                    <span>
                                                        Zaac is thinking
                                                        <span className="typing-indicator">
                                                            <span>.</span>
                                                            <span>.</span>
                                                            <span>.</span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                                {showScrollButton && (
                                    <button onClick={scrollToBottom} className="scroll-to-bottom-btn" title="Scroll to Bottom" >
                                        ↓
                                    </button>
                                )}
                            </>

                        }
                        <div className="chatbot-inp-parent">

                            {messages?.length == 0 &&
                                <div>
                                    <div className='chat-main-parent mb-4'>
                                        <div className='chat-main ai-chat mb-0'>
                                            Hi! My name is Zaac, the Creator Assistant. How can I help you?
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className='chat-icon' title='New Chat'>
                                <img src={chatIcon} alt="" />
                            </div>
                            <form className="chatbot-inp" onSubmit={handleSubmit}>
                                <div className="w-100 max-height-200px overflow-auto">
                                    <AutoResizeTextarea
                                        ref={textareaRef}
                                        value={value}
                                        onChange={handleChange}
                                        minHeight="18px"
                                        placeholder="Ask anything here..."
                                        name="msg"
                                        onKeyDown={handleKeyDown}
                                        defaultClass={null}
                                        customClass="bg-transparent borderless p-0 focus-none resize-none w-100 fs-12px"
                                    />
                                </div>
                                {isLoading ?
                                    <button onClick={handlePause} type='submit' className="grey-pause-btn">
                                        <div className='pause-icon'></div>
                                    </button> :
                                    <button disabled={isLoading} type='submit' className="grey-send-btn">
                                        <img src={sendIcon} alt="" />
                                    </button>
                                }
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupportChatbot;
