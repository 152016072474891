import { combineReducers } from "redux";

import Login from "./loginReducer";
import walkthroughSteps from "./walkthrough";
import slugReducer from "./slug";
import customizePageReducer from "./customizePage"
import blockReducer from "./blocks"
import customizeProfileReducer from "./customizeTabs/profile"
import customizeThemeReducer from "./customizeTabs/theme"
import allThemesReducer from "./customizeTabs/allThemes"
import customizeSocialsReducer from "./customizeTabs/socials"
import customizeDomainsReducer from "./customizeTabs/domains"
import customizeSeoReducer from "./customizeTabs/seo"
import integrationGoogleReducer from "./customizeTabs/integrations/google"
import integrationFacebookReducer from "./customizeTabs/integrations/facebook"
import integrationMailChimpReducer from "./customizeTabs/integrations/mailChimp"
import integrationConvertkitReducer from "./customizeTabs/integrations/convertkit"
import integrationSendinblueReducer from "./customizeTabs/integrations/sendinblue";
import profilePicUploadReducer from "./profile-upload-modal";
import seoCroppieElement from "./customizeTabs/seoCroppie"
import uploadImageReducer from "./uploadImage";
import editLinkModal from "./editLinkModal"
import newsletterModalReducer from "./newsletter/newsletter-modal"
import productModalReducer from "./product-modal";
import upgradeModalReducer from "./upgrade-modal";
import proReducer from "./pro";
import newUserReducer from "./new-user";
import plantypeReducer from "./plan-type";
import editlinkProfilePicUploadReducer from "./editlink-profile-upload-modal";
import seoPicUploadReducer from "./seo-upload-modal";
import customThemeColorReducer from "./custom-theme-colors";
import bannerModal from "./banner-modal";
import bannerSelected from "./banner-selected";
import linkStyleSelected from "./link-style";
import accountsModalReducer from "./accounts-modal";
import domainDotModalShowReducer from "./customizeTabs/domainDotModalShow";
import stripeConnectReducer from "./stripe-connect";
import meetConnectedReducer from "./meet-connected";
import eligibilityReducer from "./is-eligible";
import codeModalReducer from "./code-modal";
import integrationZapierReducer from "./customizeTabs/integrations/zapier";
import billingsReducer from "./billings";
import productDetailReducer from "./product-details";
import productCheckoutReducer from "./product-checkout";
import productContentReducer from "./product-content";
import productTierReducer from "./product-tiers";
import allLanguagesReducer from "./customizeTabs/allLanguages";
import customizeFontReducer from "./customizeTabs/font";
import allFontsReducer from "./customizeTabs/allFont";
import audienceTagsReducer from "./audience-tag";
import projectDisabledReducer from "./project-disabled";
import userDataReducer from "./user-data";
import productImagesReducer from "./product-images";
import productSavingReducer from "./product-saving";
import isProjectLoadedReducer from "./project-loaded";
import integrationCustomCodeReducer from "./customizeTabs/integrations/customCode";
const appReducer = combineReducers({
  Login,
  walkthroughSteps, slugReducer, customizePageReducer,customizeProfileReducer, customizeThemeReducer,
  allThemesReducer, customizeSocialsReducer, customizeDomainsReducer, customizeSeoReducer, integrationGoogleReducer,
  integrationFacebookReducer, integrationMailChimpReducer, integrationSendinblueReducer, integrationConvertkitReducer, blockReducer,
  seoCroppieElement, profilePicUploadReducer,seoPicUploadReducer, editlinkProfilePicUploadReducer, uploadImageReducer, editLinkModal, newsletterModalReducer, productModalReducer, upgradeModalReducer,
  proReducer,newUserReducer, customThemeColorReducer, bannerModal, bannerSelected, accountsModalReducer, domainDotModalShowReducer, stripeConnectReducer, plantypeReducer,meetConnectedReducer,eligibilityReducer,linkStyleSelected,codeModalReducer,integrationZapierReducer,billingsReducer,productDetailReducer,productCheckoutReducer,productContentReducer,productTierReducer,
  allLanguagesReducer, customizeFontReducer,allFontsReducer,audienceTagsReducer
  ,projectDisabledReducer,userDataReducer,productImagesReducer,productSavingReducer,isProjectLoadedReducer,
  integrationCustomCodeReducer
});

const reducers = (state, action) => {
  return appReducer(state, action);
};

export default reducers;
