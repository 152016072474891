import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap"
import returnIcon from "../../../assets/img/modals/return.svg";
import plusIcon from "../../../assets/img/thin-plus-icon.svg";
import { hideLoader, showLoader } from "../../loader";
import { apiUrl } from "../../../utils/url";
import ErrorMessage from "../../errors/errorMessage";
import { useAlert } from "../../../contexts/alertContext";
import { useDispatch, useSelector } from "react-redux";

export const InputModal = ({ show, handleClose, title, prefix, placeholder, name, isEdit = false, data = {}, getSocials }) => {
    const [formValues, setFormValues] = useState({});
    const { slug } = useSelector(state => state?.slugReducer);
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const { setAlert } = useAlert();
    const { customizeSocialsReducer } = useSelector(state => state)
    const handleChange = (e) => {
        setFormValues({...formValues, [e.target.name]:e.target.value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let end_point = isEdit;
        showLoader();
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData();

        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                formdata.append(key, formValues[key]);
            }
        }
        formdata.append("project", slug);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + `/project/social/${end_point ? "update" : "create"}`, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    getSocials();
                    setAlert({ type: 'success', title: `Block ${end_point ? "Updated" : "Added"}` });
                    hideLoader()
                    handleClose()
                }
                else {
                    setAlert({ type: 'error', title: response?.status_message })
                }
                hideLoader()
            }).catch((err) => {
                hideLoader()
            })

    }
    useEffect(() => {
        if (show) {
            setFormValues({...data, value:data?.value});
        }
    },[show])
    return (
        <Modal className="new-confirmation-modal" show={show} onHide={handleClose} centered>
            <Modal.Header className="borderless pb-0">
                <Modal.Title className="font-weight-500 fs-22px">
                    <div className="blue-btn py-0 shadow-none">
                        <img src={plusIcon} alt="" />
                    </div>
                    <div className="mt-15px text-capitalize">
                        {title}
                    </div>
                    <ErrorMessage error={error} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0 font-weight-500 fs-14px mt-20px">
                <div className="zaapGlobal-input d-flex align-items-center">
                    <div className="text-gray">
                        {prefix}
                    </div>
                    <input
                        type="text"
                        className="bordelss p-0 fs-12px"
                        placeholder={placeholder}
                        value={formValues?.value}
                        name="value"
                        onChange={handleChange}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="borderless justify-content-start gap-3 mt-20px pt-0">
                <button className="close btn btn-grey btn-semi-rounded d-flex align-items-center justify-content-center fs-12px font-weight-500 m-0" onClick={handleClose}>
                    <span>Cancel</span>
                    <small className="badge bg-light text-dark p-1 ms-2">Esc</small>
                </button>
                <button onClick={handleSubmit} className="new-primary-btn m-0" type="submit" autoFocus>
                    Add Social Icon
                    <small className="primary-badge">
                        <img src={returnIcon} alt="" />
                    </small>
                </button>
            </Modal.Footer>
        </Modal>
    );
};
