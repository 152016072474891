import React, { useEffect, useState } from 'react';
import chevron_down from "../../assets/img/modals/chevron-down.svg"

const CustomDropdown = ({ options = [], defaultOption, onSelect, reducerKey=null, dropdownLabel="label", valueKey="value" }) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  useEffect(() => {
    if (defaultOption) {
      setSelectedOption(defaultOption);
    }
  }, [defaultOption])

  const handleSelect = (option) => {
    // console.log("option",option)
    setSelectedOption(option?.[dropdownLabel]);
    if (onSelect) onSelect(option?.[valueKey], reducerKey);
  };
  return (
    <div>
      <div className="project-dropdown-container" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
        <span className='text-capitalize'>
          {selectedOption}
        </span>
        <img src={chevron_down} width={6} height={3} alt="" />
      </div>
      <ul className="dropdown-menu min-width-auto p-1" aria-labelledby="dropdownMenuButton">
        {options?.length > 0 && options?.filter(option => option?.[dropdownLabel] !== selectedOption).map((option, index) => (
          <li key={index}>
            <button className="filter-dropdown-item" onClick={() => handleSelect(option)}>
              {option?.[dropdownLabel] ?? ""}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomDropdown;
