import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../common/customDropdown';
import defaultUser from "../../assets/media/user_pp/default.png";
import plusIcon from "../../assets/img/thin-plus-icon.svg";
import trashIcon from "../../assets/img/page/trash.svg";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { hideLoader, showLoader } from '../loader';
import { useUpgradeModal } from '../routes/dashboard-layout';
import { getChangedFields } from '../../pages/new-page-editor';
import ImageCropper from '../croppie-main';

const Profile = ({ handleFormChanges,showSaveChanges  }) => {
    const value = useSelector(state => state?.customizeProfileReducer);
    const [profileFormState, setProfileFormState] = useState({ ...value });
    const languages = useSelector(state => state.allLanguagesReducer)
    const pro = useSelector(state => state?.proReducer);
    const fileupload = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { showUpgradeModal } = useUpgradeModal();
    const upgradeModalData = { from: 'customizePageModal', showModal: true, heading: 'Upgrade to access this Feature.', text: ' This is a Pro Feature<br /> Upgrade to Zaap Pro to unlock this feature.', isUltra: false, }
    const { slug, basePath } = useSelector(state => state?.slugReducer);
    const [faviconImg, setFaviconImg] = useState(null);
    const handleChange = (e) => {
        dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: { ...value, [e.target.name]: e.target.value } })
    }
    const profileUploaderOpener = () => {
        showLoader()
        setTimeout(() => {
            hideLoader()
            dispatch({ type: "SET_PROFILE_PIC_MODAL", payload: { show: true, to: "profile" } });
        }, 300);
    }
    const removePP = () => {
        dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: { ...value, "image_url": null } })
    };
    const handleClick = (e) => {
        e.preventDefault();
        if (!pro) {
            showUpgradeModal(upgradeModalData)
        } else {
            fileupload.current.click();
        }
    }
    const deleteImage = () => {
        setFaviconImg(null);
        dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: { ...value, "favicon_upload": null } })
    }
    const onfilechange = (e) => {
        if (!pro) {
            showUpgradeModal(upgradeModalData)
        }
        const fileList = e.target.files[0];
        dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: { ...value, [e.target.name]: fileList } })
        var reader = new FileReader();

        reader.onload = function (event) {
            setFaviconImg(event.target.result);
        };
        reader.readAsDataURL(fileList);
    }
    const handleDropdownChange = (val, key) => {
        dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: { ...value, [key]: val } })
    };
    useEffect(() => {
        handleFormChanges('profileValue', value, profileFormState)
    }, [value]);
      useEffect(() => {
          if (!showSaveChanges?.show) {
            setProfileFormState({ ...value });
          }
      },[showSaveChanges?.show])
    console.log("profile value", value)
    return (
        <div className='blocks-content new-page-editor-section scrollbar-on-hover'>
            <div className='page-editor-heading'>
                Details
            </div>
            <div className='d-flex gap-30px flex-column'>
                <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>Tilte</div>
                        <div className='details-inp-desc'>The title of your page</div>
                    </div>
                    <div className='second-half'>
                        <input type="text" className='project-new-inp' name="title" onChange={handleChange} placeholder="Page Title" value={value?.title} />
                    </div>
                </div>
                <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>Description</div>
                        <div className='details-inp-desc'>Give a short description for your page</div>
                    </div>
                    <div className='second-half'>
                        <div className='project-new-inp'>
                            <textarea className='transparent-textarea p-0' name="description" onChange={handleChange} placeholder='Description' value={value?.description} id=""></textarea>
                            <div className='fs-10px text-end'>
                                <span>
                                    {value?.description?.length}
                                </span>
                                <span className='text-grey'>
                                    /225
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>Username</div>
                        <div className='details-inp-desc'>The url name of your page</div>
                    </div>
                    <div className='second-half'>
                        <div className='d-flex align-items-center project-new-inp'>
                            <span className='text-gray'>
                                zaap.bio/
                            </span>
                            <input className='p-0 fs-12px' type="text" name="slug" onChange={handleChange} placeholder="Page Slug" value={value?.title} />
                        </div>
                    </div>
                </div>
                <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>Language</div>
                        <div className='details-inp-desc'>The language for your page</div>
                    </div>
                    <div className='second-half'>
                        <CustomDropdown onSelect={handleDropdownChange} valueKey="value" reducerKey="page_language" dropdownLabel="name" options={languages} defaultOption={languages?.find(val => val?.short_code == value?.page_language)?.name} />
                    </div>
                </div>
                <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>NSFW Content</div>
                        <div className='details-inp-desc'>Show a NSFW warning before visiting the profile.</div>
                    </div>
                    <div className='second-half'>
                        <CustomDropdown onSelect={handleDropdownChange} dropdownLabel="label" options={[
                            { label: 'Enabled', value: true },
                            { label: 'Disabled', value: false },
                        ]} defaultOption={value?.nsfw} reducerKey="nsfw" />
                    </div>
                </div>
                <div className='page-editor-heading mt-30px'>
                    Images
                </div>
                <div className='details-form-group justify-content-start gap-20px'>
                    <div>
                        <div className='details-inp-label'>Profile Picture</div>
                        <img className='pp-icon mt-10px' src={value?.image_url ? value?.image_url : defaultUser} width="100%" alt="" />
                    </div>
                    <div className='d-flex align-items-center gap-10px'>
                        <button type="button" className="upload-pp-btn" onClick={profileUploaderOpener}>
                            <img className='filter-invert-1' width={12} src={plusIcon} alt="" />
                            Upload New
                        </button>
                        <button type='button' onClick={removePP} class="upload--profile-btn borderless">
                            <img src={trashIcon} width={15} alt="" />
                        </button>
                    </div>
                </div>
                <div className='details-form-group justify-content-start gap-20px'>
                    <div>
                        <div className='details-inp-label'>Favicon</div>
                        <img className='pp-icon mt-10px'  src={faviconImg ? faviconImg : (value?.favicon_upload) ?? 'https://imagedelivery.net/tqC70bVt8T6GtQUXNsa2-g/6ac9f7a7-81fc-4982-7f41-daa9c5b2e400/public'} width="100%" alt="" />
                    </div>
                    <div className='d-flex align-items-center gap-10px'>
                        <label type="button" className="upload-pp-btn">
                            <input id="file-upload" type="file" ref={fileupload} className="d-none" accept=".png,.jpg,.jpeg" name="favicon_upload" onChange={onfilechange} />
                            <img className='filter-invert-1' width={12} src={plusIcon} alt="" />
                            Upload New
                        </label>
                        <button type='button' onClick={deleteImage} class="upload--profile-btn borderless">
                            <img src={trashIcon} width={15} alt="" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile