const INIT_STATE = {
    loaded: false
}
const isProjectLoadedReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_PROJECT_LOADED':
            return action.payload 
        case 'RESET_PROJECT_LOADED':
            return INIT_STATE
        default:
            return state
    }

}
export default isProjectLoadedReducer