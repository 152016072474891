import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../loader';
import { apiUrl, pageUrl, protocol } from '../../utils/url';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import plusIcon from "../../assets/img/thin-plus-icon.svg";
import trashIcon from "../../assets/img/page/trash.svg";
import openedEyeIcon from "../../assets/img/page/opened-eye-icon.svg";
import closedEyeIcon from "../../assets/img/page/closed-eye-icon.svg";
import gearPageIcon from "../../assets/img/page/gear.svg";
import visibleIcon from "../../assets/img/page/mini-graph-icon.svg";
import { mainPath } from '../../pages/page-editor';
import SocialModal from './modals/social-modal';
import { InputModal } from './modals/input-modal';
import dragIcon from "../../assets/img/page/drag-icon.svg";
import editIcon from "../../assets/img/page/edit-block.svg"
import returnIcon from "../../assets/img/modals/return.svg"
import duplicateIcon from "../../assets/img/duplicate-Icon.svg";
import { ReactSortable } from 'react-sortablejs';
import NewConfirmationModal from '../common/new-confirmation-modal';
import { useAlert } from '../../contexts/alertContext';

const Socials = ({ setIframeURL, setIframeIndex }) => {
    const draggableIcon = () => <img src={dragIcon} id="drag" className="cursor-grab" alt="" />

    const dispatch = useDispatch()
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [socials, setSocials] = useState([]);
    const value = useSelector(state => state?.customizeSocialsReducer);
    const history = useHistory();
    const [modal, setModal] = useState({});
    const { setAlert } = useAlert();
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null, callback: null });
    const getAllSocials = () => {
        showLoader()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders
        };
        fetch(apiUrl + `/project/social/get-all`, requestOptions)

            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    setSocials(response?.data?.socials);
                    hideLoader();
                }
            }).catch((err) => {
                hideLoader();
            })
    }
    const showModal = (data) => {
        setModal(data)
    }
    useEffect(() => {
        if (socials?.length == 0) {
            getAllSocials()
        }
    }, [])
    const handleClose = () => {
        setModal({})
    }
    const editModal = (block) => {
        if (!block || !block?.details) return;

        const { details, ...data } = block;
        const { name, prefix, social_id, placeholder, title } = details;

        setModal({
            name,
            prefix,
            data: data,
            placeholder,
            title: 'Edit ' + title,
            show: true,
            isEdit: true,
        });
    };
    const sendDragRequest = (sortedList) => {
        // Create links array with correct order
        const links = sortedList.map((item, index) => ({
            link_id: item.id,
            order_no: index + 1  // Starting from 1 to match backend expectation
        }));

        fetch(`${apiUrl}/project/social/sort`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                request_type: 'order',
                links: links,
                project: slug,
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log('Sorting updated successfully');
                }
            })
            .catch(err => console.error('Sorting error:', err));
    };

    const handleSort = (newList) => {
        // Update parent state
        dispatch({
            type: "SET_CUSTOMIZE_SOCIALS",
            payload: { ...value, socials: newList }
        });
        sendDragRequest(newList);
    };
    const onDelete = (val) => {
        setconfirmationModalShow({
            show: true,
            icon: trashIcon,
            icon_width: 15,
            icon_class: 'filter-invert-1 filter-brightness-100',
            title: 'Delete ' + val?.details?.title,
            message: 'Are you sure that you want to delete ' + val?.details?.title + '?',
            buttonText: 'Yes, Delete',
            callBackTo: 'delete',
            id: val?.id
        });
    }
    const onDuplicate = (val) => {
        setconfirmationModalShow({
            show: true,
            icon: duplicateIcon,
            icon_width: 15,
            icon_class: 'filter-invert-1 filter-brightness-100',
            title: 'Duplicate ' + val?.details?.title,
            message: 'Are you sure that you want to duplicate ' + val?.details?.title + '?',
            buttonText: 'Yes, Duplicate',
            callBackTo: 'duplicate',
            id: val?.id
        });
    }
    const confirmedDeleteBlock = (e) => {
        showLoader()
        if (confirmationModalShow?.id) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            var requestOptions = {
            };
            const formdata = new FormData();
            var api = `${apiUrl}/project/social/`
            if (confirmationModalShow?.callBackTo == "delete") {
                requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow'
                }
                api = `${apiUrl}/project/social/delete/${confirmationModalShow?.id}`
            }
            else {
                api = `${apiUrl}/project/social/duplicate`

                formdata.append("project", slug)
                formdata.append("id", confirmationModalShow?.id)

                requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: 'follow',
                    body: formdata
                }
            }

            // setconfirmationModalShow({ show: false, text: null, id: null })
            fetch(api, requestOptions)
                .then(async (res) => {
                    const response = await res?.json();
                    if (response?.status_code == 200) {
                        const block = value?.socials.find(block => block.id === confirmationModalShow?.id);
                        getSocials();
                        hideLoader()
                        if (confirmationModalShow?.callBackTo == "delete") {
                            setAlert({ type: 'success', title: `${block?.details?.title} Icon Deleted` });
                        } else {
                            setAlert({ type: 'success', title: `${block?.details?.title} Icon Duplicated` });
                        }
                    }
                    else {
                        setAlert({ type: 'error', title: `${response?.status_message}` });
                        hideLoader()
                    }
                    setconfirmationModalShow({ show: false, text: null, id: null })
                })
                .catch(() => {
                    setconfirmationModalShow({ show: false, text: null, id: null })
                    hideLoader()
                })
        }

    }
    const getSocials = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };

        fetch(apiUrl + `/project/social/get/` + slug, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    dispatch({
                        type: "SET_CUSTOMIZE_SOCIALS", payload: {
                            ...value, socials: response?.data?.socials,
                        }
                    })
                }
                else {
                    setAlert({ type: 'error', title: response?.status_message })
                }
                hideLoader()
            }).catch((err) => {
            })
    };
    const handleStatus = (e, val) => {
        let status = val?.is_enabled ? 0 : 1;
        showLoader();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData()
        formdata.append("block", val?.id)
        formdata.append("project", slug)
        formdata.append("status", status)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formdata
        };
        fetch(apiUrl + "/project/social/status", requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    const index = value?.socials.findIndex(block => block?.id === val?.id);
                    if (index !== -1) {
                        const updatedBlockList = [...value?.socials];
                        updatedBlockList[index] = {
                            ...updatedBlockList[index],
                            is_enabled: !updatedBlockList[index]?.is_enabled
                        };

                        dispatch({ type: "SET_CUSTOMIZE_SOCIALS", payload: { ...value, socials: updatedBlockList } });
                    }
                    setAlert({ type: 'success', title: `Icon ${status == 1 ? 'Enabled' : 'Disabled'}` });
                }
                hideLoader()
            }).catch((err) => {
                hideLoader()
            })
    }
    useEffect(() => {
        setTimeout(() => {
            setIframeURL(protocol + '' + pageUrl + '/' + slug + '?iframe=1');
            setIframeIndex((iframeIndex) => iframeIndex + 1);
        }, 1500);
    },[value?.socials])
    console.log("socials value", value)
    return (
        <div>
            <div className='page-editor-heading'>
                Social Icons
            </div>
            <ReactSortable
                className="editor-main d-flex flex-column gap-10px mt-20px"
                handle="#drag"
                list={value?.socials}
                setList={handleSort}
                animation={200}
                delay={2}
            >
                {
                    value?.socials?.length > 0 && value?.socials?.map(block => (
                        <div className={`content-block-main ${block.is_enabled == 0 ? 'disabled pe-none' : 'block'}`}>
                            <div className='d-flex gap-3 align-items-center first-half'>
                                {draggableIcon()}
                                <div className='blue-icon'>
                                    <div className="socials-svg" id="drag" dangerouslySetInnerHTML={{ __html: block?.details?.svg }} />
                                </div>
                                <div className="d-flex align-items-start justify-content-center flex-column">
                                    <span className='content-block-main-text text-truncate'>{block?.details?.name}</span>
                                </div>
                            </div>
                            <div className='d-flex align-items-center gap-1 second-half'>
                                <span className="btn-ededed">
                                    <img src={visibleIcon} width={12} height={18} alt="" />
                                    <span className='fs-12px ms-2'>{block?.clicks}</span>
                                </span>
                                <button className="btn-ededed pe-auto" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={gearPageIcon} width={18} alt="" />
                                </button>
                                <ul className="dropdown-menu pe-auto" aria-labelledby="table-action-dropdown">
                                    <li>
                                        <span onClick={() => editModal(block)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                            <img className='opacity-100' src={editIcon} alt="" />
                                            <span className="me-2">Edit</span>
                                        </span>
                                    </li>
                                    <li>
                                        <span onClick={(e) => handleStatus(e, block)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                            <img src={block?.is_enabled ? closedEyeIcon : openedEyeIcon} alt="" />
                                            <span className="me-5">{block?.is_enabled ? 'Hide' : 'Un-hide'} </span>
                                        </span>
                                    </li>
                                    <li>
                                        <span onClick={() => onDuplicate(block)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                            <img width={15} height={14} src={duplicateIcon} alt="" />
                                            <span className="me-5">Duplicate</span>
                                        </span>
                                    </li>
                                    <li>
                                        <span onClick={() => onDelete(block)} className="p-1 d-flex align-items-center cursor-pointer dropdown-item gray-hover justify-content-start gap-2">
                                            <img width={15} height={14} src={trashIcon} alt="" />
                                            <span className="me-5">Delete</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ))
                }
            </ReactSortable>
            <button className="blue-btn w-100 mt-20px"
                onClick={() => showModal({ show: true, type: "social_modal", blocks: socials })}>
                <img src={plusIcon} alt="" width={10} />
                <span>Add Social Icons</span>
            </button>

            {modal?.show &&
                modal?.type == 'social_modal' ? <SocialModal setModal={setModal} handleClose={handleClose} {...modal} />
                :
                <InputModal handleClose={handleClose} {...modal} getSocials={getSocials} />
            }
            <NewConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} callback={confirmedDeleteBlock} />
        </div>
    )
}

export default Socials