import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IroColorPicker, { handleSubmitCustomTheme } from "../IroColorPicker"
import IroCircularColorPicker from "../IroCircularColorPicker";

const SelectedCustomTheme = ({ color, themeType, nthChild }) => {
    console.log("color",color)
    const dispatch = useDispatch()
    const { productModalReducer, customizeThemeReducer, customizePageReducer } = useSelector(state => state);
    const [inputColor, setInputColor] = useState("#ffffff")
    const onColorChange = color => {

        dispatch({
            type: "SET_CUSTOM_COLOR", payload: {
                ...color,
                [themeType]: color.hexString
            }
        })
        // 
        // console.log(color.hexString);
    };
    const handleChangeColor = (e) => {
        // try {
        // if (color.length <= 6 || (e.target.value.charCodeAt(0)) != "08") {
        clearTimeout(typingTimer);
        if ($(e.target).val()) {
            typingTimer = setTimeout(() => {
                dispatch({
                    type: "SET_CUSTOM_COLOR", payload: {
                        ...color,
                        [themeType]: $(e.target).val()
                    }
                })
            }, doneTypingInterval);
        }
        // }
        // }
        // catch { }
    }
    useEffect(() => {
        setInputColor(color)
    }, [color]);
    const closeModal = () => {
        handleSubmitCustomTheme("SET_CUSTOMIZE_THEME", dispatch, customizeThemeReducer)
        $('.color-picker-main').removeClass('d-flex');
        $('.color-picker-main').addClass('d-none');
    };
    const applyChanges = () => {
        handleSubmitCustomTheme("SET_CUSTOMIZE_THEME", dispatch, customizeThemeReducer, themeType)
    }
    return (
        <>
            <div className="project-theme-wheel color-picker-main" id="iro-picker-container">
                <div className="text-grey-u fs-12px mb-4 text-">⚠️ Currrently Editing <span className="text-capitalize">{themeType}</span> Color</div>
                <IroCircularColorPicker color={inputColor} themeType={themeType} onColorChange={onColorChange} width={111} boxHeight={111} layoutDirection="horizontal" display="flex" justifyContent="center" />
                <div className="mt-30px d-flex justify-content-between align-items-center gap-20px">
                    <div className="d-flex align-items-center gap-1 zaap-normal-text color-picker-div">
                        <span id={`hex_code-${themeType}-label`} style={{ background: inputColor }}></span>
                        <input type="text" value={inputColor} name="" id={`hex_code-${themeType}`} className="w-100" style={{ borderWidth: "1px" }} />
                    </div>
                    <button className="dark-btn h-auto w-100" onClick={applyChanges} style={{ padding: "9px 2.7px" }}>Apply Color</button>
                </div>
            </div>

        </>

    )
}
export default SelectedCustomTheme