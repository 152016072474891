import React from "react";

const TextLengthCount = ({text = "", maxLength = 255, customClass = "", showLimit = true, countLineBreaks=true}) => {
  const charCount = countLineBreaks ? text?.replace(/(\r\n|\n|\r)/g,"  ")?.length : text?.length;
  const remainingCount = maxLength - charCount;
  const isOverLimit = charCount > maxLength;

  return (
    <div className={`fs-12px ${customClass} ${isOverLimit ? "text-danger" : "text-gray"}`}>
      {isOverLimit
        ? `- ${Math.abs(remainingCount)}`
        : `${charCount}${showLimit ? "/" + maxLength : ""}`}
    </div>
  );
};

export default TextLengthCount;
