import { useHistory } from "react-router-dom"
import { hideLoader, showLoader } from "../../loader"
import { Modal } from "react-bootstrap"
import { mainPath } from "../../../pages/page-editor"
import { useDispatch, useSelector } from "react-redux";
import { audiencePosts as eventTracking } from "../../../services/postRequests/audience";
import { apiUrl } from "../../../utils/url";
import linkblock from "../../../assets/img/blocks/link-folder.svg"
import zaapPost from "../../../assets/img/blocks/zaap-post.svg"
import singlelink from "../../../assets/img/blocks/single-link.svg"
import textblock from "../../../assets/img/blocks/textblock.svg"
import embed from "../../../assets/img/blocks/embed.svg"
import contactBlock from "../../../assets/img/blocks/contact-pro-block.svg"
import youtubeBlock from "../../../assets/img/blocks/youtube-pro-block.svg"
import instagramBlock from "../../../assets/img/blocks/instagram-pro-block.svg"
import tiktokBlock from "../../../assets/img/blocks/tiktok-pro-block.svg"
import productBlock from "../../../assets/img/blocks/product-pro-block.svg"
import coachingBlock from "../../../assets/img/blocks/coaching-pro-block.svg"
import leadMagnetBlock from "../../../assets/img/blocks/lead-magnet.svg"
import emailBlock from "../../../assets/img/blocks/block3.svg"
import Teammoadal from "../../teammember/team_modal";
import { useState } from "react";
import CreateLinkModal from "./sub-modals/createLinkModal";
import CreateProductWidgetModal from "./sub-modals/createProductWidgetModal";
import CreateMeetingProductWidgetModal from "./sub-modals/createMeetingProductWidgetModal";
import spotifySync from "../../../assets/img/blocks/spotify-sync.svg"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const BlockEditor = ({ handleClose, show, slug, blockModal = null, handleSubmit = null, returnIcon = null, setBlockModal }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [showProModal, setShowProModal] = useState(false)
    const basePath = useSelector(state => state?.slugReducer?.basePath)
    const isPro = useSelector(state => state?.proReducer)
    const planType = useSelector(state => state?.plantypeReducer)
    const isNewUser = useSelector(state => state?.newUserReducer)
    const [searchQuery, setSearchQuery] = useState("")
    // console.log("isNewUser",isNewUser,"isPro",isPro);
    // const show = (isNewUser && !isPro) || (isPro && !isNewUser); 
    const showModal = (paramsName) => {
        showLoader();
        if (!isPro && (paramsName.includes("Youtube") || paramsName.includes("Tiktok") || paramsName.includes("Twitter") || paramsName.includes("Contact") || paramsName.includes("Product") || paramsName.includes("Instagram") || paramsName.includes("ZaapPosts"))) {
            // handleClose()
            // setShowProModal(true);
            history.push(basePath + "/settings")
            hideLoader()
            return
        }
        // else if(isNewUser && planType != "ultra" && paramsName.includes("Product")){
        //     handleClose()
        //     history.push(basePath + "/settings")
        //     hideLoader()
        //     return
        // }
        // if ( !isPro && (paramsName.includes("Youtube") || paramsName.includes("Tiktok") || paramsName.includes("Twitter") || paramsName.includes("Contact"))) {
        //     handleClose()
        //     history.push(basePath + "/settings")
        //     // dispatch({ type: "SET_UPGRADE_MODAL", payload: { isPro: true, open: true } })
        //     hideLoader()
        //     return
        // }
        history.push({
            pathname: `${basePath}/${mainPath}/${paramsName}`,
            state: { showChild: true,
            parent_block_id: blockModal?.parent_id ?? null
             }
        })
        hideLoader()
    }
    const eventTrackforContact = (event) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("term", "Dynamic block clicked");
        formdata.append("project", slug);
        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        eventTracking(null, api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        }).catch((err) => {
            // console.log(err))
        })
        // console.log(event)

        // console.log(event)
        showModal("newBlockModal/createContactModal")
    }
    const eventTrackforTikTok = (event) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("term", "Dynamic block clicked");
        formdata.append("project", slug);
        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        eventTracking(null, api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        });

        showModal("newBlockModal/createTiktokWidgetModal")
    }

    const eventTrackforInstagram = (event) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("term", "Dynamic block clicked");
        formdata.append("project", slug);
        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        eventTracking(null, api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        });

        showModal("newBlockModal/createInstagramWidgetModal")
    }

    const eventTrackforTwitter = (event) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("term", "Dynamic block clicked");
        formdata.append("project", slug);
        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        eventTracking(null, api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        })
        showModal("newBlockModal/createTwitterWidgetModal")
    }
    const eventTrackforYoutube = (event) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("term", "Dynamic block clicked");
        formdata.append("project", slug);
        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        eventTracking(null, api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        })

        showModal("newBlockModal/createYoutubeWidgetModal")

    }
    const showChildModal = (params) => {
        if (!isPro && (params.includes("Product"))) {
            handleClose()
            setShowProModal(!isPro);
            hideLoader()
            return
        }
        handleClose();
        history.push({
            pathname: `${basePath}/${mainPath}/${params}`,
            state: {
                showChild: true,
                parent_block_id: blockModal?.parent_id
            }
        })
    };
    const blocks = [
        { id: 1, name: 'folder link Block', image: linkblock, onClick: () => showModal("newBlockModal/createFolderModal"), category: 'Basics' },
        { id: 2, name: 'Single Link Block', image: singlelink, onClick: () => showModal("newBlockModal/createLinkModal"), category: 'Basics' },
        { id: 4, name: 'Text Block', image: textblock, onClick: () => showModal("newBlockModal/createTextBlockModal"), category: 'Basics' },
        { id: 5, name: 'Product store Block', image: productBlock, onClick: () => showModal("newBlockModal/createProductWidgetModal"), category: 'Store' },
        { id: 11, name: 'Coaching meeting Block', image: coachingBlock, onClick: () => showModal("newBlockModal/createMeetingProductWidgetModal"), category: 'Store' },
        { id: 12, name: 'Lead Magnet Block', image: leadMagnetBlock, onClick: () => showModal("newBlockModal/createLeadMagnetWidgetModal"), category: 'Store' },
        { id: 9, name: 'Embed external ifram Block', image: embed, onClick: () => showModal("newBlockModal/createEmbedBlockModal"), category: 'Content' },
        { id: 10, name: 'Spotify Sync Block', image: spotifySync, onClick: () => showModal("newBlockModal/createSpotifySyncBlockModal"), category: 'Content' },
        { id: 6, name: 'YouTube Block', image: youtubeBlock, onClick: eventTrackforYoutube, category: 'Content' },
        { id: 7, name: 'TikTok Block', image: tiktokBlock, onClick: eventTrackforTikTok, category: 'Content' },
        { id: 13, name: 'Zaap Post', image: zaapPost, onClick: () => showModal("newBlockModal/createZaapPostsModal"), category: 'Content' },
        { id: 8, name: 'Instagram Block', image: instagramBlock, onClick: eventTrackforInstagram, category: 'Content' },
        { id: 3, name: 'email subscribe newsletter Block', image: emailBlock, onClick: () => showModal("newBlockModal/createEmailWidgetModal"), category: 'Marketing' },
        { id: 14, name: 'Contact form Block', image: contactBlock, onClick: eventTrackforContact, category: 'Marketing' },
    ];

    const handleSearch = (e) => {
        setSearchQuery(e?.target?.value?.toLowerCase());
    };
    const filteredBlocks = blocks.filter(block =>
        block?.name?.toLowerCase()?.includes(searchQuery) ||
        block?.category?.toLowerCase()?.includes(searchQuery)
    );
    const renderBlocks = (blocksToRender) => {
        return blocksToRender?.length > 0 ?
            blocksToRender.map(block => (
                <div key={block.id} className="new-block-div mt-15">
                    <img data-id={block.id} src={block.image} onClick={block.onClick} width="100%" alt={block.name} />
                </div>
            ))
            :
            <div className="text-left mt-20px">No blocks found</div>
    };
    return (
        <Modal
            centered
            show={show} onHide={handleClose} size={"lg"} id="newBlockModal">
            <div className=" modal-dialog-centered modal-mlarge" role="document">
                <div className="modal-content p-0" style={{ borderRadius: "30px" }}>
                    <div className="modal-header create-biolink-header" style={{ border: "none", paddingBottom: "0px", paddingTop: '5px' }}>
                        {blockModal?.from == "folder_block" && <h5 className="modal-title create-biolink-heading">Add To Folder</h5>}
                    </div>
                    <div className="modal-body" style={{ padding: "0px" }}>
                        <div className="add-block-modal-body-main">
                            <div className="mb-4 w-100 h-100">

                                <div className="new-block-main-div block-nav-u">
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <div className="d-flex flex-column mt-20px w-50">
                                            <div className="fs-18px font-weight-500">Zaap Blocks</div>
                                            <div className="new-input-area zaapGlobal-input mt-15px">
                                                <span className="input-prefix">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.89333 2.08766C3.45223 2.08766 1.47333 4.02468 1.47333 6.41411C1.47333 8.80355 3.45223 10.7406 5.89333 10.7406C8.33443 10.7406 10.3133 8.80355 10.3133 6.41411C10.3133 4.02468 8.33443 2.08766 5.89333 2.08766ZM0 6.41411C0 3.2282 2.63854 0.645508 5.89333 0.645508C9.14813 0.645508 11.7867 3.2282 11.7867 6.41411C11.7867 7.74716 11.3247 8.9746 10.549 9.95142L14.5176 13.8361C14.8053 14.1177 14.8053 14.5742 14.5176 14.8558C14.2299 15.1374 13.7634 15.1374 13.4758 14.8558L9.50719 10.9712C8.50924 11.7305 7.25523 12.1827 5.89333 12.1827C2.63854 12.1827 0 9.60003 0 6.41411Z" fill="#6B6B6B" />
                                                    </svg>
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder={"Search Blocks..."}
                                                    value={searchQuery}
                                                    className="p-0"
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </div>
                                        <button type="button" onClick={handleClose} className="btn-ededed" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.244078 0.244078C0.569515 -0.0813592 1.09715 -0.0813592 1.42259 0.244078L5 3.82149L8.57741 0.244078C8.90285 -0.0813592 9.43049 -0.0813592 9.75592 0.244078C10.0814 0.569515 10.0814 1.09715 9.75592 1.42259L6.17851 5L9.75592 8.57741C10.0814 8.90285 10.0814 9.43049 9.75592 9.75592C9.43049 10.0814 8.90285 10.0814 8.57741 9.75592L5 6.17851L1.42259 9.75592C1.09715 10.0814 0.569515 10.0814 0.244078 9.75592C-0.0813592 9.43049 -0.0813592 8.90285 0.244078 8.57741L3.82149 5L0.244078 1.42259C-0.0813592 1.09715 -0.0813592 0.569515 0.244078 0.244078Z" fill="#333333" />
                                            </svg>
                                        </button>
                                    </div>
                                        {blockModal?.from === "folder_block" ? (
                                            <div className="row">
                                                {renderBlocks(filteredBlocks.filter(block => ['Single Link', 'Product Block', 'Coaching Block'].includes(block.name)))}
                                            </div>
                                        ) : (
                                            searchQuery ? (
                                                <div className="row">
                                                    {renderBlocks(filteredBlocks)}
                                                </div>
                                            ) : (
                                                ['Basics', 'Store', 'Content', 'Marketing'].map(category => (
                                                    <div key={category} className="row">
                                                        <div className="mt-u">
                                                            <span className="add-block--modal-heading">{category}</span>
                                                        </div>
                                                        {renderBlocks(blocks.filter(block => block.category === category))}
                                                    </div>
                                                ))
                                            )
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showProModal && <Teammoadal showModal={showProModal} heading={'Upgrade to Unlock This Feature.'} text={' This is a PRO only Feature, Subscribe to<br /> PRO to unlock Analytics'} isUltra={false} />}
        </Modal>
    )
}
export default BlockEditor