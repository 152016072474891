

import { Modal } from "react-bootstrap"
import addImage from "../../../../assets/img/modals/add-image.svg";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import EditLinkProfilePicUploadModal from "../../../customize-picture-upload-modal";
import UploadImageBanner from "./uploadImageBanner";
import linkIcon from "../../../../assets/img/modals/link-icon.svg"
import thumbnailIcon from "../../../../assets/img/thumbnail-icon.svg"
import ErrorMessage from "../../../errors/errorMessage";
import { hideLoader, showLoader } from "../../../loader";
import CroppieModal from "../../../croppie-modal";
import { toast } from "react-toastify"


let croppie_image = { url: "", id: null };
const EditLinkModal = ({ show, returnIcon, handleHide, handleSubmit, error }) => {
    console.log("show",show)
    const dispatch = useDispatch();
    const [value, setValue] = useState({
        link_title: null,
        destination_url: null,
        link_description: null,
        open_in_same_tab: "1",
        imageData: null,
        button_text: null,

    });

    const [croppie, setCroppie] = useState(null);
    const uploadImageReducer = useSelector(state => state.uploadImageReducer);
    const editLinkModal = useSelector(state => state.editLinkModal)
    const [editLinkTab, setEditLinkTab] = useState(1);
    const [showCroppieModal, setshowCroppieModal] = useState(false);
    const profilePicUploadReducer = useSelector(state => state.profilePicUploadReducer);
    const { bannerModal } = useSelector(state => state);
    let [bannersData, setbannersData] = useState({ editLink: "", selected: null })
    const [croppedThumbnail, setCroppedThumbnail] = useState(null);

    const handleTabChange = (e) => {
        setEditLinkTab(e.target.value)
    }

    const handleChange = (e) => {
        if (e.target.name == "open_in_same_tab") {
            setValue({ ...value, [e.target.name]: e.target.id == "switch_left" ? 1 : 0 });
            dispatch({
                type: "SET_EDIT_LINK_MODAL", payload: {
                    ...editLinkModal,
                    [e.target.name]: e.target.id == "switch_left" ? 1 : 0
                }
            })
            return
        }
        dispatch({
            type: "SET_EDIT_LINK_MODAL", payload: {
                ...editLinkModal,
                [e.target.name]: e.target.value
            }
        })
    }

    
// console.log("editLinkModal", editLinkModal)
    useEffect(() => {
        setValue({
            ...value,
            link_title: editLinkModal?.name,
            destination_url: editLinkModal.destination_url,
            link_description: editLinkModal.link_description,
            open_in_same_tab: editLinkModal.open_in_same_tab,
            image_url: editLinkModal?.image_url,
        })



    }, [editLinkModal]);
    // bannerModal
    const hideModal = (uploadModal) => {
        if (uploadModal)
            dispatch({ type: "SET_BANNER_MODAL", payload: { from: "editor-link", show: true } })

        setTimeout(() => {
            dispatch({
                type: "SET_EDIT_LINK_MODAL", payload: {
                    ...editLinkModal,
                    show: false,
                }
            })
            dispatch({ type: "SET_UPLOAD_IMAGE", payload: { "editLink": null } })

            dispatch({ type: "SET_EDITLINK_PROFILE_PIC_MODAL", payload: { show: false, to: "page-editor" } })
        }, 300);
    };

    const thumbnailUpload = () => {
        showLoader()
        $("#thumbnail_image").click()
        hideLoader()
    }

    
    const dropzoneChange = (e) => {
        showLoader()
        setTimeout(() => {
            generate_image_preview(e.target);
            hideLoader();
        }, [500])
    }


    
    function generate_image_preview(input) {

        showLoader();
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            //   alert(input.files[0].size);
            if (input.files[0].size >= 5242880) {
                handleHideCropie()
                toast.error("File Size Should not exceed 5MBs")
                return;
            }
            var val = input.files[0].name;
            var valid_extensions = ['png', 'jpg', 'jpeg'];
            var extension = val.substring(val.lastIndexOf('.') + 1, val.length) || val;
            if (!valid_extensions.includes(extension)) {
                toast.error('Invalid Image File. Should be png, jpg or jpeg');
                return;
            }
            
            setshowCroppieModal(true)
            reader.onload = event => {
                croppie_image = {
                    url: event.target.result,
                };
                const el = document.getElementById("image_demo2");
                try {
                    if (el) {
                        var croppieInstance = new Croppie(el, {
                            enableExif: true,
                            viewport: {
                                width: 320,
                                height: 180,
                                type: 'square'
                            },
                            boundary: {
                                width: 350,
                                height: 350
                            }
                        });
                        croppieInstance.bind({
                            url: event.target.result,
                        }).then(function () {
                            
// console.log('jQuery bind complete');
                            setTimeout(() => {

                            }, 300);
                        }).catch(() => {
                            croppieClose()
                        })
                        setCroppie(croppieInstance)
                    }
                } catch { }

            };
            reader.readAsDataURL(input.files[0]);
        }
    };

    const blobToDataURL= (blob, callback) => {
        var a = new FileReader();
        a.onload = function(e) {callback(e.target.result);}
        a.readAsDataURL(blob);
    }

    const handleHideCropie = () => {
        $(".dropzone").val(null)
        setshowCroppieModal(false);
        setCroppie(null)
        croppie_image = { url: "", id: null };
    };
    const croppieClose = () => {
        // showLoader()
        setshowCroppieModal(false);
        setCroppie(null)
        croppie_image = { url: "", id: null };
    };
    
    const croppedImage = () => {
        if (croppie !== null) {
            showLoader();
            // return;
            croppie.result({
                size: "original",
                format: "png",
                quality: 1,
                type: "blob"
            })
            .then((response) => {
                
// console.log(response);
                blobToDataURL(response, function(dataurl){
                    $(".link-btn-image2 img").attr('src',dataurl);
                });
                dispatch({
                    type: "SET_EDIT_LINK_MODAL", payload: {
                        ...editLinkModal,
                        ['thumbnail_image_file']: response
                    }
                })
                setCroppedThumbnail(response);
                hideLoader();
                croppieClose();

            }).catch(() => {
                croppieClose()
            })
                    
        }
    };
    //         useEffect(() => {
    // if(!editLinkModal?.link_image)
    //             dispatch({
    //                 type: "SET_EDIT_LINK_MODAL", payload: {
    //                     ...editLinkModal, imageData: uploadImageReducer?.editLink, "image_url": uploadImageReducer?.image_url, link_image: uploadImageReducer?.link_image
    //                 }
    //             })
    //         }, [uploadImageReducer]);
    return (
        <>
            <Modal centered size="large" show={show} onHide={!editLinkModal?.show ? handleHide : () => hideModal()} id="editLinkModal">
                <div className="modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div class="modal-body">

                            <div class="form-group">

                                <img src={linkIcon} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Edit link</h4>
                                <p class="text-gray font-weight-500">Edit the details for this link.</p>
                            </div>

                            <form className="update_link_modal_form" method="post" name="update_biolink_" data-linkid="" role="form" encType="multipart/form-data" onSubmit={(e) => {
                                e.preventDefault();
                                let values = { ...editLinkModal }
                                if (values.link_image) {
                                    delete values.imageData;
                                    delete values.image_url;
                                }
                                handleSubmit(e, values, editLinkModal?.show ? editLinkModal?.id : "link", true)

                            }}>

                                <input type="hidden" name="request_type" value="update" />
                                <input type="hidden" name="type" value="biolink" />
                                <input type="hidden" name="subtype" value="link" />
                                <input type="hidden" name="link_id" id="update_link_modal_link_id" />

                                <div class="notification-container"></div>

                                <ErrorMessage error={error} />
                                <div class="col-md-6 col-12 d-none">

                                    <div class="form-group">
                                        <label><small>Link Title</small></label>
                                        <input type="text" class={`form-control zaap-form-control`} name="link_title" required="required" value={editLinkModal?.link_title} onChange={handleChange} placeholder="My Links" />
                                    </div>

                                    <div class="form-group">
                                        <label><small>Destination URL</small></label>
                                        <input type="text" class={`form-control zaap-form-control`} name="destination_url" required="required" value={editLinkModal?.destination_url} onChange={handleChange} placeholder="https://mywebsite.com" />
                                    </div>

                                    <input type="hidden" name="defaultImage" id="update_biolink_default_image" />
                                    <input type="hidden" name="new_upload" id="update_biolink_new_upload" value='0' />

                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-7 col-12 d-flex align-items-center flex-column">

                                        <div class="form-group w-100 p-0">
                                            <div class="up-in-toggle bg-white d-flex align-items-center justify-content-start" style={{ boxShadow: 'none', padding: "0", marginBottom: "10px" }}>
                                                <input type="radio" id="link_details_radio" name="edit_link_tab" checked={editLinkTab == 1 ? true : false} value="1" onChange={handleTabChange} />
                                                <label htmlFor="link_details_radio" style={{ width: "100px", borderRadius: '7px' }}>Link details</label>
                                                <input type="radio" id="advanced_settings_radio" name="edit_link_tab" checked={editLinkTab == 2 ? true : false} value="2" onChange={handleTabChange} />
                                                <label htmlFor="advanced_settings_radio" style={{ width: "140px", borderRadius: '7px' }}>Advanced settings</label>
                                            </div>
                                        </div>

                                        <div className={editLinkTab == 1 ? 'w-100' : 'd-none'} style={{ height: "250px" }}>

                                            <div class="form-group w-100 p-0">
                                                <label><small>Link Title</small></label>
                                                <input type="text" class={`form-control zaap-form-control`} name="link_title" required="required" value={editLinkModal?.link_title} onChange={handleChange} placeholder="My Links" />
                                            </div>

                                            <div class="form-group w-100 p-0 mt-3">
                                                <label><small>Link Description</small></label>
                                                <input type="text" class={`form-control zaap-form-control`} name="link_description" required="required" value={editLinkModal?.link_description} onChange={handleChange} placeholder="Explain what this link is" />
                                            </div>

                                            <div class="form-group w-100 p-0 mt-3">
                                                <label><small>Link URL</small></label>
                                                <input type="text" class={`form-control zaap-form-control`} name="destination_url" required="required" value={editLinkModal?.destination_url} onChange={handleChange} placeholder="https://mywebsite.com" />
                                            </div>
                                        </div>

                                        <div className={editLinkTab == 2 ? 'w-100' : 'd-none'} style={{ height: "250px" }}>

                                            <div class="form-group w-100 p-0">
                                                <label><small>Button Text</small></label>
                                                <input type="text" class={`form-control zaap-form-control`} name="button_text" required="required" value={editLinkModal?.button_text} onChange={handleChange} placeholder="Discover" />
                                            </div>


                                            <div class="form-group w-100 p-0 mt-4">
                                                <label><small>Open link in:</small></label>
                                                <div class="up-in-toggle bg-white mt-1">
                                                    <input type="radio" id="switch_left" name="open_in_same_tab" checked={editLinkModal?.open_in_same_tab == 1 ? true : false} value="1" onChange={handleChange} />
                                                    <label htmlFor="switch_left">Current Tab</label>
                                                    <input type="radio" id="switch_right" name="open_in_same_tab" checked={editLinkModal?.open_in_same_tab == 0 ? true : false} value="0" onChange={handleChange} />
                                                    <label htmlFor="switch_right">New Tab</label>
                                                </div>
                                            </div>

                                            <div className="dropzone-wrapper dropzone-wrapper4 product-dropzone mb-2 mt-3 link-thumbnail-upload-dropzone" onClick={thumbnailUpload} style={{ background: "transparent", border: "1px dashed #A0A0A0", height: 65 }}>
                                                <img src={thumbnailIcon} width={40}/>
                                                <span>Upload Thumbnail</span>
                                                <input type="file" accept=".png,.jpg,.jpeg" name="thumbnail_image" className="dropzone" onChange={dropzoneChange} />
                                            </div>
                                        </div>




                                    </div>
                                    <div class="col-5 d-md-flex d-none align-items-end">

                                        <div data-link-id="" class="link-card" style={{ width: "auto" }}>
                                            <button type="button" class="btn thumbnail-upload-sm-btn add-image" onClick={() => hideModal(true)}>
                                                <img src={addImage} width="20px" alt="" />
                                            </button>
                                            <div>
                                                <a href="#" onclick="" style={{ width: "220px" }} class="btn btn-block  link-btn ">
                                                    <div class="link-btn-image-wrapper " ></div>
                                                    <div class="link-img-div2">
                                                        <div class="link-btn-image2" id="update-biolink-image-preview" style={editLinkModal?.image_url ? { paddingTop: 0 } : null} loading="lazy">
                                                            <img src={editLinkModal?.image_url ? editLinkModal?.image_url : uploadImageReducer?.image_url} width="100%" alt="" /></div>
                                                    </div>

                                                    <h5 class="link-title2" id="update_biolink_title_show">{editLinkModal?.link_title}</h5>
                                                    <p class="link-description2 text-gray" id="update_biolink_description_show">{editLinkModal?.link_description}</p>
                                                    <div class="div-block-3422"><div class="text-block-20 discover_btn" id="update_biolink_button_text_show">{editLinkModal?.button_text}
                                                    </div></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 update-link-images-main"></div>
                                </div>

                                <div class="text-center mt-4 d-flex">
                                    <button type="button" onClick={!editLinkModal?.show ? handleHide : () => hideModal()} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                        <b class="me-2">Cancel</b>
                                        <small class="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="submit" className="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                        <b className="me-2">Update Changes</b>
                                        <small className="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal>
            <CroppieModal show={showCroppieModal} handleHide={handleHideCropie} croppieClose={croppieClose} croppedImage={croppedImage} to={profilePicUploadReducer?.to} from={bannerModal?.from} />
            {/* <EditLinkProfilePicUploadModal showprofilePicModal={showprofilePicModal?.show} to={showprofilePicModal?.to} editLinkModal={editLinkModal} setValue={setProfile} from="editLink" /> */}

        </>
    )
}
export default EditLinkModal